import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import LinearLoader from '../Common/Loader';
import Header from '../../containers/Header';
import '../../styles/pages/goals-review/goalsReview.scss';
import { CycleTab } from './CycleTab';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../config/utils';
import { currentDashboardTab, hidePerformanceCycle } from '../../action/common';
import { UserPerformTabPanel, UserPerformTabs } from '../UserPerformance/UserPerformTabs';
import { SelfReview } from './SelfReview';
import { TeamReview } from './TeamReview';
import { GoalReviewTabPanel, GoalReviewTabs } from './GoalReviewTabs';

export const GoalsReview = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetails = getUserDetails();
	const { dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);
	const { currentGoalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);

	const [tabSelected, setTabSelected] = useState(0);
	const [loader, setLoader] = useState<boolean>(false);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	useEffect(() => {
		dispatch(hidePerformanceCycle(true));
	}, []);
	useEffect(() => {
		if (dashboardCurrentTab !== tabSelected) {
			setTabSelected(dashboardCurrentTab);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardCurrentTab]);

	const handleTabChange = (event: any, newValue: any) => {
		setTabSelected(newValue);
		dispatch(currentDashboardTab(newValue));
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='page-subheader' id='page-perform-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('goalReviewLevel')}</Typography>
						</Box>
						<GoalReviewTabs
							tabSelected={tabSelected}
							handleTabChange={handleTabChange}
							{...props}
							userDetails={userDetails}
						/>
					</Box>
					<Box className='page-subheader-right'>
						<CycleTab {...props} fromReview={true} />
					</Box>
				</Box>
			</Box>
			<Box className='pad-container'>
				<GoalReviewTabPanel value={tabSelected} index={0}>
					<SelfReview {...props} fromTeamDetails={false} drUserData={userDetails} backClicked={() => {}} />
				</GoalReviewTabPanel>
				<GoalReviewTabPanel value={tabSelected} index={1}>
					<TeamReview {...props} fromTeamDetails={false} drUserData={{}} backClicked={() => {}} />
				</GoalReviewTabPanel>
				{okrMasterData?.isApprovals && (
					<GoalReviewTabPanel value={tabSelected} index={2}>
						<SelfReview {...props} fromTeamDetails={false} drUserData={{}} backClicked={() => {}} />
					</GoalReviewTabPanel>
				)}
			</Box>
		</>
	);
};
