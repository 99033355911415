import { Font, StyleSheet } from '@react-pdf/renderer';
// import OKR_Arial from '../../../fonts/arial.ttf';
// import OKR_ArialBold from '../../../fonts/arialbd.ttf';
// import OKR_ArialItalic from '../../../fonts/ariali.ttf';
// import OKR_ArialBoldItalic from '../../../fonts/arialbi.ttf';
// Font.register({
// 	family: 'arial',
// 	format: 'truetype',
// 	fonts: [
// 		{ src: OKR_Arial, fontWeight: 400 }, // font-style: normal, font-weight: normal
// 		{ src: OKR_ArialBold, fontWeight: 700 },
// 		{ src: OKR_ArialItalic, fontStyle: 'italic' },
// 		{ src: OKR_ArialBoldItalic, fontWeight: 700, fontStyle: 'italic' },
// 	],
// });
export const styles = StyleSheet.create({
	page: {
		backgroundColor: '#eef5ff',
		paddingBottom: 35,
		fontFamily: 'Helvetica',
	},
	page_wrap: {
		paddingTop: 0,
		paddingHorizontal: 0,
		paddingLeft: 30,
		paddingRight: 30,
		borderTopWidth: 5,
		borderTopColor: '#CD0CFF',
		borderTopStyle: 'solid',
	},
	header_top: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
		paddingBottom: 20,
		paddingTop: 20,
	},
	text1_small: {
		fontSize: 11,
		fontWeight: '400',
		color: '#292929',
	},
	text1: {
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
		paddingRight: 5,
	},
	meeting_title_content: {
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
		paddingRight: 5,
	},
	meeting_title_wrap: {
		maxWidth: '95%',
	},
	text2: {
		fontWeight: '600',
		fontSize: 14,
		color: '#292929',
	},
	text3: {
		fontWeight: '600',
		fontSize: 13,
		color: '#292929',
	},
	flex_content: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
	},

	okr_logo: {
		//width: 128,
		height: 25,
	},
	pageNumber: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: '#848484',
		fontSize: 12,
		paddingTop: 10,
		paddingBottom: 10,
	},
	users_info: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
	},
	header_middle: {
		paddingTop: 0,
		paddingBottom: 10,
	},
	profile_details: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
	},
	user_image: {
		width: 25,
		height: 25,
		borderRadius: 100,
		marginRight: 8,
	},
	user_initial: {
		width: 25,
		height: 25,
		borderRadius: 100,
		marginRight: 8,
		backgroundColor: '#ffdbdb',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	user_initial_name: {
		fontSize: 10,
		fontWeight: 400,
		fontFamily: 'Helvetica-Bold',
		color: '#b24002',
		textTransform: 'uppercase',
	},
	user_name: {
		fontSize: 14,
		color: '#292929',
		paddingBottom: 3,
	},
	meeting_section: {
		paddingBottom: 30,
	},
	meeting_title: {
		paddingTop: 20,
		paddingBottom: 20,
	},

	meeting_agenda: {
		paddingBottom: 20,
	},
	meeting_title_text: {
		paddingBottom: 5,
		fontSize: 14,
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	meeting_content: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'flex-start',
		width: '100%',
		backgroundColor: '#ffffff',
		border: '1.4px solid #cee3ff',
		padding: '10px 5px',
		borderRadius: 5,
	},
	meeting_content_head: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'flex-start',
		width: '100%',
	},
	profile_owner: {
		backgroundColor: '#ffffff',
		borderRadius: 100,
		padding: '2px 5px',
		marginLeft: 5,
	},
	profile_owner_name: {
		color: '#416390',
		textTransform: 'uppercase',
		fontSize: 8,
		fontWeight: 400,
	},
	meeting_notes: {
		padding: '10px',
		backgroundColor: '#f4f8ff',
		borderRadius: 5,
		marginBottom: 15,
		width: '100%',
	},
	private_notes: {
		padding: '10px',
		backgroundColor: '#f4f8ff',
		borderRadius: 5,
		marginBottom: 0,
		width: '100%',
	},
	other_meeting_notes: {
		padding: '10px',
		backgroundColor: '#f4f8ff',
		borderRadius: 5,
		marginBottom: 0,
		width: '100%',
	},
	meeting_notes_inner: {
		backgroundColor: '#ffffff',
		border: '1px solid #cdcdcd',
		padding: '10px',
		borderRadius: 5,
		marginTop: 5,
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
	},
	meeting_notes_inner_wrap: {
		maxWidth: '100%',
	},
	meeting_agenda_wrap: {
		maxWidth: '100%',
		color: '#292929!important',
	},
	meeting_tasks_inner_wrap: {
		maxWidth: '100%',
	},
	meeting_notes_wrap: {
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
	},
	not_agenda: {
		fontSize: 11,
		fontWeight: 400,
		color: '#292929!important',
	},
	meeting_col2: {
		paddingTop: 0,
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		width: '100%',
	},
	notes_text: {
		fontSize: 10,
		fontWeight: '400',
		color: '#292929',
	},
	not_found: {
		paddingTop: 20,
		paddingBottom: 20,
		fontSize: 11,
		fontWeight: 400,
		textAlign: 'center',
		color: '#292929',
	},
	meeting_private_notes: {
		paddingTop: 20,
		paddingBottom: 10,
	},
	h1: {
		fontSize: 18,
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		margin: 0,
		color: '#292929',
	},
	h2: {
		fontSize: 16,
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		margin: 0,
		color: '#292929',
	},
	h3: {
		fontSize: 14,
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		margin: 0,
		color: '#292929',
	},
	h4: {
		fontSize: 12,
		margin: 0,
		color: '#292929',
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
	},
	h5: {
		fontSize: 12,
		margin: 0,
		color: '#292929',
	},
	p: {
		margin: '0 0 10px 0px',
		padding: 0,
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
	},

	a: {
		color: '#292929',
		cursor: 'pointer',
	},
	b: {
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	strong: {
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	i: {
		fontStyle: 'italic',
		fontFamily: 'Helvetica-Oblique',
		color: '#292929',
	},
	ul: {
		margin: '5px 0 10px 2px',
		padding: 0,
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
		//width: '100px',
		maxWidth: '85%',
	},
	li: {
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
	},
	ol: {
		margin: '5px 0 10px 2px',
		padding: 0,
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
		//width: '100px',
	},
	table: {
		borderCollapse: 'collapse',
		width: '100%',
	},

	td: {
		padding: '8px',
		borderCollapse: 'collapse',
		fontSize: 11,
		fontWeight: 400,
		borderColor: '#efefef',
		borderWidth: 1,
		borderStyle: 'solid',
		color: '#292929',
	},

	th: {
		padding: '8px',
		borderCollapse: 'collapse',
		fontSize: 11,
		color: '#292929',
	},
	task_action: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'flex-start',
		paddingTop: '5px',
		maxWidth: '95%',
		flexWrap: 'wrap',
	},
	task_row: {
		paddingTop: '12px',
		paddingBottom: '12px',
		borderBottomColor: '#efefef',
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
	},
	first_row: {
		paddingTop: '0px',
	},
	last_row: {
		borderBottomWidth: '0px',
	},
	action_icon: {
		width: 10,
		//height: 14,
		marginRight: '5px',
	},
	task_action_col: {
		paddingRight: '12px',
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
	},
	task_action_label: {
		fontSize: 9,
		color: '#416390',
	},
	task_action_label_txt: {
		fontSize: 9,
		color: '#292929',
	},
	task_action_top: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		paddingTop: '5px',
		width: '100%',
	},
	meeting_date: {
		paddingBottom: 20,
	},
	meeting_attendees: {
		paddingBottom: 20,
	},
	meeting_attendees_inner: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
	},
	nature_of_meeting: {
		paddingBottom: 20,
	},
	meeting_link: {
		paddingBottom: 20,
	},
	meeting_link_content: {
		color: '#0664b1',
		fontSize: 11,
		fontWeight: 400,
		paddingRight: 5,
	},
	not_link_text: {
		fontSize: 11,
		fontWeight: 400,
		color: '#292929',
	},
	meeting_schedule: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
	},
	meeting_time_text: {
		color: '#416390',
	},
});
