/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Badge,
	Box,
	IconButton,
	Drawer,
	ListItemText,
	Typography,
	Tabs,
	Tab,
	List,
	ListItem,
	Avatar,
	Button,
	Tooltip,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	MenuList,
	MenuItem,
} from '@material-ui/core';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import { DeleteIcon, KebabIcon } from '../../config/svg/CommonSvg';
import { NotificationIcon } from '../../config/svg/HeaderActionSvg';
import {
	getQuarterStartEnd,
	getSelectedCycle,
	getTime,
	getUserName,
	generateDefaultTimeOptions,
	convertDateTimeToUserTimezone,
} from '../../config/utils';
import AlertDialog from '../Common/Dialog';
import { useTranslation } from 'react-i18next';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { useSnackbar } from 'notistack';
import Feedback from '../Feedback';
import { ENGAGE, HOME, PERFORM, USER_VIEW, RECOGNIZE, PERFORM_DEVELOPMENT } from '../../config/app-url';
import { useHistory } from 'react-router-dom';
import PersonalFeedbackMenu from '../PersonalFeedback';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import NotificationImage from '../../images/notification.svg';
import NoNotificationImage from '../../images/notification-blank.svg';
import { Enums } from '../../config/enums';
import LinearLoader from '../Common/Loader';
import { OKRButton } from '../Common/OKRButton';
import { MarkReadIcon } from '../../config/svg/DrawerSvg';
import {
	fetchNotificationForTabs,
	resetFeedbackRedirectionInfo,
	updateFeedbackRedirectionInfo,
} from '../../action/notification';
import {
	currentDashboardTab,
	currentDashboardTabForSO,
	currentDashboardTabForSONotificationRedirection,
	setNomineeDetailsForRedirection,
	setPerformanceGoalForRedirection,
	updateRoute,
	showOnCreatePopup,
	notificationOkrCycle,
} from '../../action/common';
import {
	setPipImprovementData,
	setFeedbackImprovementData,
	setThreeSixtyData,
} from '../../action/performanceImprovementPlan';
import { launchOneOnOneData } from '../../action/oneOnOneRequest';
import { OneOnOneDrawer } from './OneOnOneDrawer';
import { GLOBAL_Task } from '../../config/app-url';
import { getAllGlobalTaskDirect, globalTaskNotificationRedirection } from '../../action/globalTask';
function NotificationTabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`notification-tabpanel-${index}`}
			aria-labelledby={`notification-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
}
NotificationTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `notification-tab-${index}`,
		'aria-controls': `notification-tabpanel-${index}`,
	};
}

export default function Notification(props) {
	const {
		deleteNotificationSuccess,
		resetNotification,
		personalFeedbackAcceptReject,
		readSingleNotification,
		listOkrResult,
	} = props;
	const history = useHistory();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const isLocked = listOkrResult && listOkrResult.isLocked;
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));

	const staticTitleText = '1-on-1 Meeting';
	const defaultTimeOptions = generateDefaultTimeOptions();
	const staticData = {
		requestId: 0,
		requestedTo: 0,
		onetoOneRemark: '',
		oneToOneTitle: staticTitleText,
		isQuickLaunch: false,
		selectedUser: [],
		meetingLink: '',
		meetingStartDate: new Date(),
		meetingEndDate: new Date(),
		meetingStartTime: defaultTimeOptions[0],
		meetingEndTime: defaultTimeOptions[1],
	};
	/**
	 * Used hooks to dispatch action and et data from redux state
	 */
	const dispatch = useDispatch();
	const { notificationTabData, deleteNotificationResponse } = useSelector((state) => state.notificationReducer);

	const [openNotification, setOpenNotification] = useState(false);
	const [value, setValue] = useState(0);
	const [modalOpen, setModalOpen] = useState({ open: false, message: null, type: null, data: null });
	const [feedbackRedirection, setFeedbackRedirection] = useState(false);
	const [feedbackData, setFeedbackData] = useState();
	const [feedbackType, setFeedbackType] = useState(0);
	const [notificationBy, setNotificationBy] = useState(0);
	const [pfRedirection, setPFRedirection] = useState(false);
	const [showPersonalFeedback, setShowPersonalFeedback] = useState('');
	const [notSameCycleId, setNotSameCycleId] = useState(false);
	const [cycleYear, setCycleYear] = useState(null);
	const [quarterSymbol, setQuarterSymbol] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const pageSize = 10;
	const [notificationData, setNotificationData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [isBulkDelete, setIsBulkDelete] = useState(false);
	const [unReadMessageCount, setUnReadMessageCount] = useState(0);
	const [requestOneOnOne, setRequestOneOnOne] = useState({});
	const [openOneOnOneDrawer, setOpenOneOnOneDrawer] = useState(false);
	const [oneOnOneEntity, setOneOnOneEntity] = useState({});
	const { okrCreationInProgress } = useSelector((state) => state.commonReducer);

	useEffect(() => {
		if (notificationTabData) {
			getNotificationTabData(notificationTabData, pageIndex - 1);
		}
	}, [notificationTabData]);

	/** call fetch notification at initial loading */
	useEffect(() => {
		redirectToUrl();
		return () => {
			setModalOpen({ open: false, message: null, type: null, data: null });
			resetNotification();
		};
	}, []);

	/** delete notification tracking to show snackbar */
	useEffect(() => {
		if (deleteNotificationSuccess) {
			let messageText = t('deleteNotification');
			let variant = 'success';
			if (deleteNotificationResponse) {
				const { entity, messageList } = deleteNotificationResponse;
				if (entity) {
					const { allUnreadCount, message } = entity;
					messageText = isBulkDelete ? message || '' : messageText;
					setUnReadMessageCount(allUnreadCount || 0);
				} else {
					const errorMsg = messageList ? Object.values(messageList).join('') : messageText;
					variant = 'error';
					messageText = isBulkDelete && errorMsg;
				}
			}
			if (isBulkDelete) {
				setNotificationData([]);
			} else {
				const { id } = modalOpen.data || {};
				const updatedData = notificationData.filter((item) => item.id !== id);
				setNotificationData([...updatedData]);
			}
			if (isBulkDelete) {
				enqueueSnackbar(messageText, {
					variant,
					autoHideDuration: 5000,
					transitionDuration: { enter: 500, exit: 500 },
					// TransitionComponent: Collapse,
				});
			}
			resetNotification();
			setIsBulkDelete(false);
			setLoader(false);
			setModalOpen({ open: false, message: null, type: null, data: null });
		}
	}, [deleteNotificationSuccess]);

	// handle request one on one drawer css start here
	const handleDrawerClose = (e, type) => {
		setOpenOneOnOneDrawer(false);
	};

	const getNotificationTabId = () => {
		return value + 1;
	};

	const redirectToCycle = (cycleId, redirect = '') => {
		const cycleDetails = JSON.parse(getLocalStorageItem('cycleDetail'));
		if (props.teamCycleDetails1?.length) {
			let cycleObj = getSelectedCycle(cycleId, props.teamCycleDetails1);
			if (cycleObj?.quarter) {
				removeLocalStorageItem('isPeopleViewRedirectionInProgress', true);
				props.updateCurrentCycleId(cycleObj?.quarter);
				// history.push(HOME);
			}
		} else if (cycleDetails?.cycleDetails.length) {
			let cycleObj = getSelectedCycle(cycleId, cycleDetails?.cycleDetails);
			if (cycleObj?.quarter) {
				removeLocalStorageItem('isPeopleViewRedirectionInProgress', true);
				props.updateCurrentCycleId(cycleObj?.quarter);
			}
		}
		if (redirect === 'home') {
			setTimeout(() => {
				history.push(HOME);
			}, 1000);
		}
	};

	/** fetch notifications */
	const fetchNotifications = async (pgIndex, tabType) => {
		if (userDetail && userDetail.employeeId) {
			setLoader(true);
			setPageIndex(pgIndex + 1);
			const queryParams = `NotificationTabId=${tabType + 1}&PageIndex=${pgIndex + 1}&PageSize=${pageSize}`;
			dispatch(fetchNotificationForTabs(queryParams));
		}
	};
	const getNotificationTabData = (response, pgIndex) => {
		if (response?.data?.status === Enums.STATUS_SUCCESS) {
			const { totalPages, pageIndex, results } = response?.data?.entity || {};
			const { allUnreadCount, notificationTabResponses } = results || {};
			if (totalPages > 1 && totalPages > pageIndex) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}
			setUnReadMessageCount(allUnreadCount || 0);
			if (pgIndex === 0) {
				setNotificationData(notificationTabResponses);
			} else {
				setNotificationData([...notificationData, ...notificationTabResponses]);
			}
		}
		setLoader(false);
		setTimeout(() => {
			redirectToUrl();
		}, 1000);
	};

	/** redirect from the notification*/
	const redirectToUrl = () => {
		let redirectUrl = getLocalStorageItem('redirectUrl');
		if (Boolean(redirectUrl)) {
			redirectUrl = JSON.parse(redirectUrl);
			if (redirectUrl.url && redirectUrl.url.includes('/')) {
				if (redirectUrl.url && redirectUrl.url.includes('krAssignmentOpenDrawer')) {
					//it's for email redirection to open accept/decline KR
				} else {
					readNotifications('', redirectUrl);
					removeLocalStorageItem('redirectUrl');
				}
			}
		}
	};

	/** open notification drawer */
	const handleNotificationOpen = (event) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		props.showNotification(false);
		setNotificationData([]);
		setValue(0);
		setShowLoadMore(false);
		fetchNotifications(0, 0);
		setFeedbackRedirection(false);
		setFeedbackData({});
		setFeedbackType(0);
		setNotificationBy(0);
		setOpenNotification(true);
	};

	/** close notification drawer */
	const handleNotificationClose = () => {
		setModalOpen({ open: false, message: null, type: null, data: null });
		setOpenNotification(false);
	};

	/** tab change */
	const handleTabChange = (event, newValue) => {
		event.preventDefault();
		setValue(newValue);
	};

	/** deleting notification */
	const deleteNotification = (event, id) => {
		event.preventDefault();
		setNotSameCycleId(false);
		const data = {
			records: notificationData,
			id,
		};
		setModalOpen({
			open: true,
			message: t('notificationDeleteMessage'),
			type: 'Single Delete',
			data,
		});
	};

	const markAsReadNotification = (event, item) => {
		event.preventDefault();
		setNotSameCycleId(false);
		// need call Mark as Read/Unread API
		readNotificationAPICall('Single', item, getNotificationTabId());
	};

	const handleCloseModal = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		if (type === 2) {
			setLoader(true);
			if (modalOpen.type === 'Single Delete') {
				setIsBulkDelete(false);
				const { id } = modalOpen.data || {};
				// All Call to delete single record
				const queryParam = `notificationTabId=${getNotificationTabId()}`;
				props.deleteNotification(queryParam, [id]);
			} else if (modalOpen.type === t('deleteAllLevel')) {
				const { notificationTabId, records } = modalOpen.data || {};
				if (records && records.length) {
					setIsBulkDelete(true);
					const ids = []; // notificationTabId === 1 ? [] : records.map((item) => item.id);
					const queryParam = `notificationTabId=${notificationTabId}`;
					props.deleteNotification(queryParam, [...ids]);
				} else {
					setLoader(false);
					const message = t('NoRecord');
					enqueueSnackbar(message || '', {
						variant: 'success',
						autoHideDuration: 5000,
						transitionDuration: { enter: 500, exit: 500 },
						// TransitionComponent: Collapse,
					});
				}
			}
			setModalOpen({ ...modalOpen, open: false });
		} else {
			setModalOpen({ open: false, message: null, type: null, data: null });
		}
		setQuarterSymbol(null);
		setCycleYear(null);
	};

	const resetNotificationData = () => {
		setFeedbackRedirection(false);
		setFeedbackData({});
		setNotificationBy(0);
		setFeedbackType(0);
		setPFRedirection(false);
		setShowPersonalFeedback('');
	};

	const openPersonalFeedback = (type, feedbackDetails) => {
		if (
			type === 'give' &&
			(!feedbackDetails?.feedbackProvideDetails || feedbackDetails?.feedbackProvideDetails.length === 0)
		) {
			const details = {
				showPersonalFeedback: type,
				pfRedirection: true,
				feedbackData: feedbackDetails,
			};
			dispatch(updateFeedbackRedirectionInfo(details));
		} else {
			dispatch(resetFeedbackRedirectionInfo(null));
		}
		handleNotificationClose();
		setPFRedirection(true);
		setShowPersonalFeedback(type);
	};

	const handlePerformNotification = (item) => {
		const { url } = item || {};
		const urlValues = url && url.includes('/') ? url.split('/') : [];
		const cycleId = urlValues[1] ? parseInt(urlValues[1]) : 0;
		const employeeId = urlValues[2] ? parseInt(urlValues[2]) : 0;
		const threeSixtyStatus = urlValues[3] ? parseInt(urlValues[3]) : 0;
		const nomineeForEmpId = urlValues[4] ? parseInt(urlValues[4]) : 0;

		handleReadNotification(item);

		if (threeSixtyStatus === 3) {
			const payload = {
				cycleId,
				employeeId,
				threeSixtyStatus,
				nomineeForEmpId,
			};
			dispatch(setNomineeDetailsForRedirection(payload));
		}
		dispatch(currentDashboardTabForSO(false));
		let tabToOpen = threeSixtyStatus - 1;
		dispatch(currentDashboardTab(tabToOpen));
		dispatch(updateRoute(PERFORM));
		history.push(PERFORM);
	};

	const handlePerformanceGoalNotification = (item) => {
		const { url } = item || {};
		const urlValues = url && url.includes('/') ? url.split('/') : [];
		const tabId = urlValues[1] ? parseInt(urlValues[1]) : 0;
		const cycleId = urlValues[2] ? parseInt(urlValues[2]) : 0;
		const employeeId = urlValues[3] ? parseInt(urlValues[3]) : 0;
		const categoryId = urlValues[4] ? parseInt(urlValues[4]) : 0;
		const conversationId = urlValues[5] ? parseInt(urlValues[5]) : 0;
		const conversationsReplyId = urlValues[6] ? parseInt(urlValues[6]) : 0;

		//handle conversation redirection
		if (conversationId > 0 || conversationsReplyId > 0) {
			setLocalStorageItem(
				'conversationHighlight',
				JSON.stringify({
					conversationId: conversationId,
					employeeId: employeeId,
					conversationsReplyId: conversationsReplyId,
				})
			);
		}

		handleReadNotification(item);
		handleNotificationClose();
		const payload = {
			tabId, // 1 for my goal, 2 for team goal
			cycleId,
			employeeId,
			categoryId,
			conversationId,
			conversationsReplyId,
		};
		dispatch(setPerformanceGoalForRedirection(payload));

		dispatch(currentDashboardTabForSO(false));
		dispatch(currentDashboardTab(tabId === 1 ? 6 : 7));
		dispatch(updateRoute(HOME));
		history.push(HOME);
	};

	const handlePipNotification = (item) => {
		const { url } = item || {};
		const urlValues = url && url.includes('/') ? url.split('/') : [];
		const pipId = urlValues[1] ? parseInt(urlValues[1]) : 0;
		let selectedTab = 2; // check here
		if (urlValues[0] === 'perform-feedback') {
			selectedTab = 1;
		}
		if (urlValues[0] === 'perform-threesixty') {
			selectedTab = 0;
		}
		handleReadNotification(item);
		if (pipId) {
			if (selectedTab === 1) {
				dispatch(
					setFeedbackImprovementData({
						feedbackId: pipId,
						commentId: urlValues[2] || 0,
						//commentId: urlValues[3] || 0,
					})
				);
			} else if (selectedTab === 0) {
				dispatch(
					setThreeSixtyData({
						feedbackId: pipId,
						commentId: urlValues[2] || 0,
						//commentId: urlValues[3] || 0,
					})
				);
			} else {
				dispatch(
					setPipImprovementData({
						pipId: pipId,
						reviewerId: urlValues[2] || 0,
						commentId: urlValues[3] || 0,
					})
				);
			}
		}
		dispatch(currentDashboardTab(selectedTab));
		handleNotificationClose();
		dispatch(updateRoute(PERFORM_DEVELOPMENT));
		history.push(PERFORM_DEVELOPMENT);
	};
	const handleRecognizeNotification = (item) => {
		const itemArray = item.url && item.url.split('/');
		handleNotificationClose();
		handleReadNotification(item);
		dispatch(updateRoute(RECOGNIZE));

		let recognitionId = 0;
		if (itemArray.length > 0) {
			recognitionId = itemArray[1] ? parseInt(itemArray[1]) : 0;
			setLocalStorageItem(
				'recognitionHighlight',
				JSON.stringify({
					recognitionId: recognitionId,
				})
			);
			if (itemArray.length > 2) {
				let commentDetailsId = itemArray[2] ? parseInt(itemArray[2]) : 0;
				let replyDetailsId = itemArray[3] ? parseInt(itemArray[3]) : 0;
				setLocalStorageItem(
					'recognitionCommentHighlight',
					JSON.stringify({
						recognitionId: recognitionId,
						commentDetailsId: commentDetailsId,
						replyDetailsId: replyDetailsId,
					})
				);
			} else if (itemArray.length > 1) {
				let commentDetailsId = itemArray[2] ? parseInt(itemArray[2]) : 0;
				setLocalStorageItem(
					'recognitionCommentHighlight',
					JSON.stringify({
						recognitionId: recognitionId,
						commentDetailsId: commentDetailsId,
					})
				);
			}
		}

		if (history.location.pathname === RECOGNIZE) {
			history.go();
		} else {
			history.push(RECOGNIZE);
		}
	};

	const handleNotesDifferentCycleItem = async (e, item) => {
		const cycleId = getLocalStorageItem('cycleId') || 0;
		const itemArray = item.url && item.url.split('/');
		const noteId = itemArray[1] ? parseInt(itemArray[1]) : 0;
		const goalType = itemArray[2] ? parseInt(itemArray[2]) : 0;
		const goalId = itemArray[3] ? parseInt(itemArray[3]) : 0;
		const notesCycleId = itemArray[4] ? parseInt(itemArray[4]) : 0;
		let employeeId = null;
		handleReadNotification(item);

		if (item?.url.toLowerCase().includes('notes') && parseInt(notesCycleId) !== parseInt(cycleId)) {
			setLocalStorageItem('isPeopleViewRedirectionInProgress', true);
			props.resetOkr();
			// history.push(USER_VIEW);
			history.push({
				pathname: USER_VIEW,
				state: { cycle: cycleId, route: props.currentRoute },
			});
			removeLocalStorageItem('currentUser');
			const data = `empId=${employeeId ? employeeId : item.notificationsBy}`;
			const resp = await props.getUserById(data);
			if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
				if (resp.data.entityList) {
					setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
					// history.push(USER_VIEW);
					history.push({
						pathname: USER_VIEW,
						state: { cycle: cycleId, route: props.currentRoute },
					});
				}
				setLocalStorageItem(
					'notesHighlight',
					JSON.stringify({
						noteId: noteId,
						goalType: goalType,
						goalId: goalId,
						employeeId: item.notificationsBy,
					})
				);
				redirectToCycle(notesCycleId, 'peopleView');
			}
		} else if (item?.url.toLowerCase().includes('notes')) {
			removeLocalStorageItem('currentUser');
			const data = `empId=${employeeId ? employeeId : item.notificationsBy}`;
			const resp = await props.getUserById(data);
			if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
				if (resp.data.entityList) {
					setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
				}
				props.resetOkr();
				handleNotificationClose();
				setLocalStorageItem(
					'notesHighlight',
					JSON.stringify({
						noteId: noteId,
						goalType: goalType,
						goalId: goalId,
						employeeId: item.notificationsBy,
					})
				);
				history.push({
					pathname: USER_VIEW,
					state: { cycle: cycleId, route: props.currentRoute },
				});
			}
		}
	};

	const handleConversationsDifferentCycleItem = async (e, item) => {
		const cycleId = getLocalStorageItem('cycleId') || 0;
		const itemArray = item.url && item.url.split('/');
		const conversationId = itemArray[1] ? parseInt(itemArray[1]) : 0;
		const conversationGoalType = itemArray[2] ? parseInt(itemArray[2]) : 0;
		const conversationGoalId = itemArray[3] ? parseInt(itemArray[3]) : 0;
		const employeeId = itemArray[4] ? parseInt(itemArray[4]) : 0;
		const conversationsCycleId = itemArray[5] ? parseInt(itemArray[5]) : 0;
		const conversationsReplyId = itemArray[6] ? parseInt(itemArray[6]) : 0;
		handleReadNotification(item);
		if (item?.url.toLowerCase().includes('conversations') && parseInt(conversationsCycleId) !== parseInt(cycleId)) {
			if (employeeId === userDetail.employeeId) {
				setLocalStorageItem('isPeopleViewRedirectionInProgress', true);
				removeLocalStorageItem('currentUser');
				//if request one to one Done on other's OKR
				props.resetOkr();
				setLocalStorageItem(
					'conversationHighlight',
					JSON.stringify({
						conversationId: conversationId,
						goalType: conversationGoalType,
						goalId: conversationGoalId,
						employeeId: employeeId,
						conversationsReplyId: conversationsReplyId,
					})
				);

				handleNotificationClose();
				setTimeout(() => {
					redirectToCycle(conversationsCycleId, 'home');
				}, 0);
			} else {
				setLocalStorageItem('isPeopleViewRedirectionInProgress', true);
				props.resetOkr();
				history.push(USER_VIEW);
				removeLocalStorageItem('currentUser');
				//if request one to one Done on self OKR
				const data = `empId=${employeeId}`;
				const resp = await props.getUserById(data);
				if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
					if (resp.data.entityList) {
						setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
					}
					history.push(USER_VIEW);
					setLocalStorageItem(
						'conversationHighlight',
						JSON.stringify({
							conversationId: conversationId,
							goalType: conversationGoalType,
							goalId: conversationGoalId,
							employeeId: employeeId,
							conversationsReplyId: conversationsReplyId,
						})
					);
					redirectToCycle(conversationsCycleId);
				}
			}
		} else if (item?.url.toLowerCase().includes('conversations')) {
			if (employeeId === userDetail.employeeId) {
				//if request one to one Done on other's OKR
				removeLocalStorageItem('currentUser');
				props.resetOkr();
				setLocalStorageItem(
					'conversationHighlight',
					JSON.stringify({
						conversationId: conversationId,
						goalType: conversationGoalType,
						goalId: conversationGoalId,
						employeeId: employeeId,
						conversationsReplyId: conversationsReplyId,
					})
				);
				handleNotificationClose();
				history.push(HOME);
			} else {
				//if request one to one Done on self OKR
				const data = `empId=${employeeId}`;
				const resp = await props.getUserById(data);
				if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
					if (resp.data.entityList) {
						setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
					}
					handleNotificationClose();
					props.resetOkr();
					setLocalStorageItem(
						'conversationHighlight',
						JSON.stringify({
							conversationId: conversationId,
							goalType: conversationGoalType,
							goalId: conversationGoalId,
							employeeId: employeeId,
							conversationsReplyId: conversationsReplyId,
						})
					);
					history.push(USER_VIEW);
				}
			}
		}
	};

	const handleNotesDifferentCycle = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			const type = itemArray[4] ? parseInt(itemArray[4]) : 0;
			let flag = false;
			if (notification.url && notification.url.toLowerCase().includes('notes') && type > 0) {
				flag = true;
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const handleConversationsDifferentCycle = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			const type1 = itemArray[5] ? parseInt(itemArray[5]) : 0;
			let flag = false;
			if (notification.url && notification.url.toLowerCase().includes('conversations') && type1 > 0) {
				flag = true;
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const redirectToSelectedCycle = (cycleId) => {
		if (props.teamCycleData && props.teamCycleData.length && props.updateCurrentCycleId) {
			let cycleObj = getSelectedCycle(cycleId, props.teamCycleData);
			if (cycleObj.quarter) {
				props.updateCurrentCycleId(cycleObj.quarter);
			}
		} else if (getLocalStorageItem('cycleDetail')) {
			const cycleDetails = JSON.parse(getLocalStorageItem('cycleDetail'));
			if (cycleDetails.cycleDetails.length) {
				let cycleObj = getSelectedCycle(cycleId, cycleDetails.cycleDetails);
				if (cycleObj.quarter) {
					props.updateCurrentCycleId(cycleObj.quarter);
				}
			}
		}
	};

	const handleReadNotification = (item) => {
		item?.isRead === false && callReadNotificationAPI(item);
	};

	/** read single notification */
	const readNotifications = async (e, item, isReadNotification) => {
		if (Boolean(e)) {
			e.preventDefault();
		}
		try {
			isReadNotification && !item.url.includes('PersonalFeedback/3') && handleReadNotification(item);
			if (item.url && item.url.includes('teamOkr')) {
				removeLocalStorageItem('notificationRedirection');
				const itemArray = item.url && item.url.split('/');
				const goalId = itemArray[2] ? itemArray[2].trim() : 0;
				const cycleId = itemArray[3] ? itemArray[3].trim() : 0;
				const year = itemArray[4] ? itemArray[4].trim() : 0;
				const goalDetail = await props.getGoalsDetails(`goalObjectiveId=${goalId}&cycle=${cycleId}&year=${year}`);
				if (goalDetail.status === 200) {
					removeLocalStorageItem('currentUser');
					setLocalStorageItem(
						'notificationRedirection',
						JSON.stringify({
							url: HOME,
							tabToOpen: 'TEAMSOKR',
							data: item.url,
						})
					);
					handleNotificationClose();
					props.updateRoute(HOME);
					history.push(HOME);
				}
			} else if (item.url && item.url.includes('PersonalFeedback')) {
				const itemArray = item.url && item.url.split('/');
				const type = itemArray[1] ? parseInt(itemArray[1]) : 0;
				if (type === 1) {
					//1 --- give drawer --- if requested
					const feedbackRequestId = itemArray[2] ? parseInt(itemArray[2]) : 0;
					const pfDetail = await props.personalFeedbackDetail(`feedbackRequestId=${feedbackRequestId}`);
					if (pfDetail.status === 200) {
						setFeedbackData(pfDetail.data.entity);
						openPersonalFeedback('give', pfDetail.data.entity);
					}
				} else if (type === 2) {
					//2 --- view drawer -- if gave feedback
					const feedbackDetailId = itemArray[2] ? parseInt(itemArray[2]) : 0;
					setFeedbackData({ feedbackDetailId: feedbackDetailId });
					openPersonalFeedback('view', { feedbackDetailId: feedbackDetailId });
				} else if (type === 4) {
					//4 --- given PF listing drawer -- if commented on feedback
					const feedbackDetailId = itemArray[2] ? parseInt(itemArray[2]) : 0;
					setFeedbackData({ feedbackDetailId: feedbackDetailId });
					openPersonalFeedback('viewListing', { feedbackDetailId: feedbackDetailId });
				}
			} else if (item.url && item.url.includes('Feedback')) {
				const itemArray = item.url && item.url.split('/');
				const type = itemArray[1] ? parseInt(itemArray[1]) : 0;
				const id = itemArray[2] ? parseInt(itemArray[2]) : 0;
				const orgCycleId = itemArray[5] ? parseInt(itemArray[5]) : 0;
				if (type && type === 3) {
					removeLocalStorageItem('currentUser');
					const employeeId = Boolean(itemArray[4]) ? parseInt(itemArray[4]) : 0;
					const goalType = Boolean(itemArray[3]) ? itemArray[3] : 0;
					if (employeeId === userDetail.employeeId) {
						//if request one to one Done on other's OKR
						setLocalStorageItem('goalType', goalType);
						setLocalStorageItem('highlightGoal', id);
						handleNotificationClose();
						if (orgCycleId !== parseInt(currentCycleId)) {
							redirectToSelectedCycle(orgCycleId);
							// history.push(HOME);
						} else {
							history.push(HOME);
						}
					} else {
						//if request one to one Done on self OKR
						const data = `empId=${employeeId}`;
						const goalId = id;
						const resp = await props.getUserById(data);
						if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
							if (resp.data.entityList) {
								setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
							}
							setLocalStorageItem('goalType', goalType);
							setLocalStorageItem('highlightGoal', goalId);
							setLocalStorageItem('highlightGoalKey', id);
							handleNotificationClose();
							props.resetOkr();
							if (orgCycleId !== parseInt(currentCycleId)) {
								redirectToSelectedCycle(orgCycleId);
								history.push(USER_VIEW);
							} else {
								history.push(USER_VIEW);
							}
						}
					}
				} else if (id && id !== 0 && type && type !== 0) {
					setNotificationBy(item.notificationsBy);
					setFeedbackType(type);
					const feedbackDetail = await props.redirectToFeedback(`feedbckRequestId=${id}`);
					if (feedbackDetail.status === 200) {
						setFeedbackRedirection(true);
						setFeedbackData({ ...feedbackDetail.data, feedbackRequestId: id, feedbackItem: item });
						handleNotificationClose();
					}
				}
			} else if (item.url && item.url.includes('Comments')) {
				const itemArray = item.url && item.url.split('/');
				const type = itemArray[1] ? itemArray[1].trim() : 0;
				const id = itemArray[2] ? itemArray[2].trim() : 0;
				const feedbackDetailId = itemArray[3] ? itemArray[3].trim() : 0;
				if (id && id !== 0 && type && type !== 0) {
					setNotificationBy(item.notificationsBy);
					setFeedbackType(type);
					const feedbackDetail = await props.redirectToFeedback(`feedbckRequestId=${id}`);
					if (feedbackDetail.status === 200) {
						setFeedbackRedirection(true);
						setFeedbackData({ ...feedbackDetail.data, feedbackRequestId: id, feedbackDetailId: feedbackDetailId });
						handleNotificationClose();
					}
				}
			} else if (item.url && item.url.includes('Contributors')) {
				const itemArray = item.url && item.url.split('/');
				const goalId = itemArray[1] ? itemArray[1].trim() : 0;
				let { currentYear } = getQuarterStartEnd();
				const year = getLocalStorageItem('year') || currentYear;
				const cycleId = getLocalStorageItem('cycleId') || 0;
				const goalDetail = await props.getGoalsDetails(`goalObjectiveId=${goalId}&cycle=${cycleId}&year=${year}`);
				if (goalDetail.status === 200) {
					removeLocalStorageItem('currentUser');
					setLocalStorageItem('highlightGoal', goalId);
					handleNotificationClose();
					history.push(HOME);
					props.updateRoute(HOME);
				}
			} else if (item.url && item.url.includes('Alignment')) {
				const itemArray = item.url && item.url.split('/');
				const goalId = itemArray[2] ? itemArray[2].trim() : 0;
				let { currentYear } = getQuarterStartEnd();
				const year = getLocalStorageItem('year') || currentYear;
				const cycleId = getLocalStorageItem('cycleId') || 0;
				const goalDetail = await props.getGoalsDetails(`goalObjectiveId=${goalId}&cycle=${cycleId}&year=${year}`);
				if (goalDetail.status === 200) {
					removeLocalStorageItem('currentUser');
					setLocalStorageItem('currentKey', goalId);
					setLocalStorageItem('highlightGoal', goalId);
					handleNotificationClose();
					history.push(HOME);
					props.updateRoute(HOME);
				}
			} else if (item.url && (item.url.includes('KRAcceptDecline') || item.url.includes('KrAssignment'))) {
				removeLocalStorageItem('currentUser');
				const itemArray = item.url && item.url.split('/');
				const krId = itemArray[2] ? parseInt(itemArray[2]) : 0; // this is objective id
				const objId = itemArray[3] ? parseInt(itemArray[3]) : 0; // this is kr id
				const okrCycleId = itemArray[5] ? parseInt(itemArray[5]) : 0; // this is kr id
				props.resetOkr();
				// setLocalStorageItem('highlightGoal', krId);
				setLocalStorageItem('highlightGoal', objId);
				setLocalStorageItem('highlightGoalKey', objId);
				setLocalStorageItem('goalType', objId);
				handleNotificationClose();
				dispatch(currentDashboardTabForSO(false));
				dispatch(currentDashboardTab(0));
				redirectToCycle(okrCycleId, 'home');
				//dispatch(updateRoute(HOME));
				//history.push(HOME);
			} else if (item.url && item.url.includes('userdeleted')) {
				removeLocalStorageItem('currentUser');
				const itemArray = item.url && item.url.split('/');
				const okrCycleId = itemArray[1] ? parseInt(itemArray[1]) : 0; // this is kr id
				props.resetOkr();
				handleNotificationClose();
				dispatch(currentDashboardTabForSO(false));
				dispatch(currentDashboardTab(0));
				redirectToCycle(okrCycleId, 'home');
			} else if (item.url && item.url.includes('WeightChange')) {
				removeLocalStorageItem('currentUser');
				const itemArray = item.url && item.url.split('/');
				const objId = itemArray[1] ? parseInt(itemArray[1]) : 0; // this is objective id
				const krId = itemArray[2] ? parseInt(itemArray[2]) : 0; // this is kr id
				const orgCycleId = itemArray[3] ? parseInt(itemArray[3]) : 0;
				props.resetOkr();
				setLocalStorageItem('highlightGoal', krId);
				setLocalStorageItem('highlightGoalKey', krId);
				setLocalStorageItem('goalType', krId);
				handleNotificationClose();
				if (orgCycleId !== parseInt(currentCycleId)) {
					redirectToSelectedCycle(orgCycleId);
					// history.push(HOME);
				} else {
					props.updateRoute(HOME);
					history.push(HOME);
				}
			} else if (item.url && item.url.includes('unlockaccount')) {
				// handles redirection click from email
				setLocalStorageItem('unlockUser', true);
				removeLocalStorageItem('redirectUrl');
				window.history.replaceState(null, '', 'unlock-me'); //added this because page is getting refreshed twice
			} else if (item.url && item.url.includes('ChangeLeader')) {
				handleNotificationClose();
				history.push(HOME);
				props.updateRoute(HOME);
			} else if (item.url && item.url.includes('hightlightOKR')) {
				const itemArray = item.url && item.url.split('/');
				const id = itemArray[1] ? parseInt(itemArray[1]) : 0; // Objective id
				const goalType = Boolean(itemArray[2]) ? itemArray[2] : 0;
				removeLocalStorageItem('currentUser');
				setLocalStorageItem('goalType', goalType);
				setLocalStorageItem('highlightGoal', id);
				setLocalStorageItem('highlightGoalKey', id);
				handleNotificationClose();
				history.push(HOME);
				props.updateRoute(HOME);
			} else if (item.url && item.url.includes('conversations')) {
				const itemArray = item.url && item.url.split('/');
				const conversationId = itemArray[1] ? parseInt(itemArray[1]) : 0;
				const conversationGoalType = itemArray[2] ? parseInt(itemArray[2]) : 0;
				const conversationGoalId = itemArray[3] ? parseInt(itemArray[3]) : 0;
				const employeeId = itemArray[4] ? parseInt(itemArray[4]) : 0;
				removeLocalStorageItem('currentUser');
				if (employeeId === userDetail.employeeId) {
					//if request one to one Done on other's OKR
					setLocalStorageItem('isPeopleViewRedirectionInProgress', true);
					setLocalStorageItem(
						'conversationHighlight',
						JSON.stringify({
							conversationId: conversationId,
							goalType: conversationGoalType,
							goalId: conversationGoalId,
							employeeId: employeeId,
						})
					);
					removeLocalStorageItem('isPeopleViewRedirectionInProgress');
					handleNotificationClose();
				} else {
					//if request one to one Done on self OKR
					const data = `empId=${employeeId}`;
					const resp = await props.getUserById(data);
					if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
						if (resp.data.entityList) {
							setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
						}
						props.resetOkr();
						setLocalStorageItem(
							'conversationHighlight',
							JSON.stringify({
								conversationId: conversationId,
								goalType: conversationGoalType,
								goalId: conversationGoalId,
								employeeId: employeeId,
							})
						);
						removeLocalStorageItem('isPeopleViewRedirectionInProgress');
						history.push(USER_VIEW);
					}
				}
			} else if (item.url && item.url.includes('notes')) {
				const itemArray = item.url && item.url.split('/');
				const noteId = itemArray[1] ? parseInt(itemArray[1]) : 0;
				const goalType = itemArray[2] ? parseInt(itemArray[2]) : 0;
				const goalId = itemArray[3] ? parseInt(itemArray[3]) : 0;
				const employeeId = itemArray[4] ? parseInt(itemArray[4]) : 0;
				props.resetOkr();
				const data = `empId=${employeeId ? employeeId : item.notificationsBy}`;
				const resp = await props.getUserById(data);
				if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
					if (resp.data.entityList) {
						setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
					}
					setLocalStorageItem(
						'notesHighlight',
						JSON.stringify({
							noteId: noteId,
							goalType: goalType,
							goalId: goalId,
							employeeId: employeeId,
						})
					);
					removeLocalStorageItem('isPeopleViewRedirectionInProgress');
					history.push(USER_VIEW);
				}
			} else if (item.url && item.url.includes('people-view')) {
				setLocalStorageItem('LoginAs', 'user');
				if (item.noteId && item.noteId > 0) {
					setLocalStorageItem('notesHighlight', JSON.stringify(item));
				}
				if (item.conversationId && item.conversationId > 0) {
					setLocalStorageItem('conversationHighlight', JSON.stringify(item));
				}
				const data = 'empId=' + item.employeeId;
				const goalId = item.objectiveId;
				const resp = await props.getUserById(data);
				if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
					if (resp.data.entityList) {
						setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
					}
					setLocalStorageItem('goalType', item.goalType);
					setLocalStorageItem('highlightGoal', goalId);
					removeLocalStorageItem('redirectUrl');
					if (parseInt(item.cycleId) !== parseInt(currentCycleId)) {
						redirectToSelectedCycle(parseInt(item.cycleId));
						history.push(USER_VIEW);
					} else {
						history.push(USER_VIEW);
					}
				}
			} else if (item.url && item.url.includes('OneToOne')) {
				const itemArray = item.url && item.url.split('/');
				const oneToOneDetailId = itemArray[2] ? itemArray[2].trim() : 0;
				if (oneToOneDetailId) {
					const requestParams = `requestId=${oneToOneDetailId}`;
					const oneOnOneDetails = await dispatch(launchOneOnOneData(requestParams));
					if (oneOnOneDetails && oneOnOneDetails?.data && oneOnOneDetails?.data?.status === 200) {
						const { entity } = oneOnOneDetails?.data || {};
						//console.log('1-On-1 Details', entity);
						let localStartDateTime = convertDateTimeToUserTimezone(
							entity.meetingStartDate || '',
							entity.meetingStartTime || ''
						);
						let localEndDateTime = convertDateTimeToUserTimezone(
							entity.meetingEndDate || '',
							entity.meetingEndTime || ''
						);
						entity.meetingStartDate = localStartDateTime[0] || staticData.meetingStartDate;
						entity.meetingStartTime = localStartDateTime[1] || staticData.meetingStartTime;
						entity.meetingEndDate = localEndDateTime[0] || staticData.meetingEndDate;
						entity.meetingEndTime = localEndDateTime[1] || staticData.meetingEndTime;

						setOpenOneOnOneDrawer(true);
						setOneOnOneEntity(entity);
						setOpenNotification(false);
					}
				}
			} else if (item.url && item.url.includes('StrategicObjective')) {
				const itemArray = item.url && item.url.split('/');
				const soDetailsId = itemArray[1] ? itemArray[1].trim() : 0;
				const timeFrameId = itemArray[2] ? itemArray[2].trim() : 0;
				handleNotificationClose();
				setLocalStorageItem('notificationRedirection', soDetailsId);
				dispatch(
					currentDashboardTabForSONotificationRedirection({
						soDetailsId: soDetailsId || 0,
						timeFrameId: timeFrameId || 0,
					})
				);
				dispatch(currentDashboardTabForSO(true));
				history.push(HOME);
				props.updateRoute(HOME);
			} else if (item.url && item.url.includes('task/')) {
				const itemArray = item.url && item.url.split('/');
				const globalTaskId = itemArray[1] ? itemArray[1].trim() : 0;
				const globalTaskTabId = itemArray[2] ? itemArray[2].trim() : 0;
				const conversationId = itemArray[3] ? itemArray[3].trim() : 0;
				const conversationsReplyId = itemArray[4] ? itemArray[4].trim() : 0;
				//handle conversation redirection
				if (conversationId > 0 || conversationsReplyId > 0) {
					setLocalStorageItem(
						'conversationHighlight',
						JSON.stringify({
							conversationId: conversationId,
							conversationsReplyId: conversationsReplyId,
						})
					);
				}
				if (globalTaskId && parseInt(globalTaskId)) {
					const defaultTaskListRequest = {
						isMyTask: parseInt(globalTaskTabId) && parseInt(globalTaskTabId) === Enums.TWO ? false : true,
						isTagged: parseInt(globalTaskTabId) && parseInt(globalTaskTabId) === Enums.THREE ? true : false,
						isCompleted: false,
						pageSize: Enums.FIVE,
						pageIndex: Enums.ONE,
						order: '',
						sortingText: '',
						finder: [],
						taskFilter: {
							dueDate: [],
							taskType: [],
							priority: [],
						},
						startDate: null,
						endDate: null,
						taskGroupById: Enums.ZERO,
						taskGroupByType: Enums.ZERO,
						moduleId: Enums.FOUR,
						taskId: parseInt(globalTaskId),
					};
					const taskListResp = await dispatch(getAllGlobalTaskDirect(defaultTaskListRequest));
					if (taskListResp && taskListResp?.data?.status === Enums.STATUS_SUCCESS) {
						const { entity } = taskListResp?.data || {};
						const { taskListings } = entity || {};
						if (taskListings && taskListings?.length) {
							const taskName = taskListings[0]?.taskName || '';
							const isCompleted = taskListings[0]?.isCompleted || false;
							if (taskName) {
								setLocalStorageItem('taskRedirected', true);
								handleNotificationClose();
								dispatch(
									globalTaskNotificationRedirection({
										isRedirected: true,
										taskName,
										taskId: parseInt(globalTaskId),
										tabId: parseInt(globalTaskTabId) || Enums.ONE,
										isCompleted,
									})
								);
								history.push(GLOBAL_Task);
								props.updateRoute(GLOBAL_Task);
							}
						} else {
							setLocalStorageItem('taskRedirected', true);
							handleNotificationClose();
							dispatch(
								globalTaskNotificationRedirection({
									isRedirected: true,
									taskName: '',
									taskId: Enums.ZERO,
									tabId: parseInt(globalTaskTabId) || Enums.ONE,
									isCompleted: false,
								})
							);
							history.push(GLOBAL_Task);
							props.updateRoute(GLOBAL_Task);
						}
					}
				} else {
					setLocalStorageItem('taskRedirected', true);
					handleNotificationClose();
					dispatch(
						globalTaskNotificationRedirection({
							isRedirected: true,
							taskName: '',
							taskId: Enums.ZERO,
							tabId: parseInt(globalTaskTabId) || Enums.ONE,
							isCompleted: false,
						})
					);
					history.push(GLOBAL_Task);
					props.updateRoute(GLOBAL_Task);
				}
			} else if (item.url && item.url.includes('recognize/')) {
				const itemArray = item.url && item.url.split('/');
				let recognitionId = 0;
				if (itemArray.length > 0) {
					recognitionId = itemArray[1] ? parseInt(itemArray[1]) : 0;
					setLocalStorageItem(
						'recognitionHighlight',
						JSON.stringify({
							recognitionId: recognitionId,
						})
					);
					if (itemArray.length > 2) {
						let commentDetailsId = itemArray[2] ? parseInt(itemArray[2]) : 0;
						let replyDetailsId = itemArray[3] ? parseInt(itemArray[3]) : 0;
						setLocalStorageItem(
							'recognitionCommentHighlight',
							JSON.stringify({
								recognitionId: recognitionId,
								commentDetailsId: commentDetailsId,
								replyDetailsId: replyDetailsId,
							})
						);
					} else if (itemArray.length > 1) {
						let commentDetailsId = itemArray[2] ? parseInt(itemArray[2]) : 0;
						setLocalStorageItem(
							'recognitionCommentHighlight',
							JSON.stringify({
								recognitionId: recognitionId,
								commentDetailsId: commentDetailsId,
							})
						);
					}
				}

				if (history.location.pathname === RECOGNIZE) {
					history.go();
				} else {
					history.push(RECOGNIZE);
				}
			}
		} catch (err) {}
	};

	const checkKrAssignment = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			const type = itemArray[4] ? parseInt(itemArray[4]) : 0; //=== 0 = action already been taken yet
			let flag = false;
			if (notification.url && notification.url.toLowerCase().includes('krassignment')) {
				if (
					type > 0 &&
					(notification.notificationTypeId === Enums.TWENTY_EIGHT ||
						notification.notificationTypeId === Enums.TWENTY_NINE)
				) {
					flag = true;
				} else {
					flag = false;
				}
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};
	const checkUserdeletedNotification = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			const type = itemArray[4] ? parseInt(itemArray[4]) : 0; //=== 0 = action already been taken yet
			let flag = false;
			if (notification.url && notification.url.toLowerCase().includes('userdeleted')) {
				if (
					type > 0 &&
					(notification.notificationTypeId === Enums.TWENTY_EIGHT ||
						notification.notificationTypeId === Enums.TWENTY_NINE)
				) {
					flag = true;
				} else {
					flag = false;
				}
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const checkRequestOneOnOneIfAcceptedOrDeclined = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			const type = itemArray[1] ? parseInt(itemArray[1]) : 0;
			let flag = false;
			if (notification.url && notification.url.includes('PersonalFeedback')) {
				if (type === 3 && (notification.notificationTypeId === 6 || notification.notificationTypeId === 5)) {
					flag = true;
				} else {
					flag = false;
				}
			} else if (notification.url && notification.url.toLowerCase().includes('krassignment')) {
				if (notification.notificationTypeId !== Enums.TWENTY_FIVE) {
					flag = true;
				} else {
					flag = false;
				}
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const checkRequestOneOnOneType = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			const type = itemArray[1] ? parseInt(itemArray[1]) : 0;
			let flag = false;
			if (notification.url && notification.url.includes('PersonalFeedback')) {
				if (type === 3 && notification.notificationTypeId === 4) {
					flag = true;
				} else {
					flag = false;
				}
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const checkPipCommentsNotification = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			let flag = false;
			if (notification.url && notification.url.includes('Comments/')) {
				flag = true;
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};
	const checkPipNotification = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			let flag = false;
			if (
				(notification.url && notification.url.includes('perform-development/')) ||
				notification.url.includes('perform-feedback/') ||
				notification.url.includes('perform-threesixty/')
			) {
				flag = true;
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const checkPerformanceGoalNotification = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			let flag = false;
			if (itemArray[0] && itemArray[0] === 'performance-goal') {
				flag = true;
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const checkPerformNotification = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray && itemArray.length > 1) {
			let flag = false;
			if (itemArray[0] && itemArray[0] === 'perform') {
				flag = true;
			} else {
				flag = false;
			}
			return flag;
		}
		return false;
	};

	const checkRecognizeNotification = (notification) => {
		const itemArray = notification.url && notification.url.split('/');
		if (itemArray) {
			if (itemArray.length > 1) {
				let flag = false;
				if (notification.url && notification.url.includes('recognize')) {
					flag = true;
				} else {
					flag = false;
				}
				return flag;
			}
		}
		return false;
	};

	const requestOneOnOneAcceptOrReject = (_event, notification, status) => {
		try {
			const itemArray = notification.url && notification.url.split('/');
			const notificationsDetailId = notification.id ? notification.id : 0;
			const oneToOneDetailId = Boolean(itemArray) && itemArray.length && itemArray[2] ? parseInt(itemArray[2]) : 0;
			const requestData = {
				oneToOneDetailId,
				status, // 0=Reject, 1=Approve
				notificationsDetailId,
			};
			personalFeedbackAcceptReject(requestData)
				.then((response) => {
					if (Boolean(response) && response.data && response.data.status === 200) {
						const customMessage = `${t('NotificationRequestOneOnOneCustomMessage')} ${
							status === 1 ? t('NotificationAccepted') : t('NotificationRejected')
						} successfully`;
						enqueueSnackbar(customMessage, {
							variant: 'success',
							autoHideDuration: 5000,
						});
						// readNotifications('', notification);
						setTimeout(() => {
							// Added notification API call for OP-10771
							fetchNotifications(0, value);
						}, 500);
					} else {
						const responseAPI = Boolean(response) && response.data && response.data.messageList;
						const keys = Boolean(responseAPI) && Object.keys(responseAPI);
						const messages = Boolean(keys) && keys.length && keys.map((item) => responseAPI[item]);
						enqueueSnackbar(messages, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					}
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	};

	const requestKrAssignment = async (notification, status) => {
		try {
			const itemArray = notification.url && notification.url.split('/');
			const krId = itemArray[2] ? parseInt(itemArray[2]) : 0;
			const okrId = itemArray[3] ? parseInt(itemArray[3]) : 0;
			if (krId > 0) {
				handleReadNotification(notification);
				handleNotificationClose();
				removeLocalStorageItem('currentUser');
				setLocalStorageItem(
					'redirectUrl',
					JSON.stringify({
						url: `krAssignmentOpenDrawer/${krId}/${okrId}/${status}`,
					})
				);
				history.push(HOME);
				props.updateRoute(HOME);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleNotificationMenuToggle = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setOpenNotificationMenu((prev) => !prev);
	};

	const handleNotificationMenuClose = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(null);
		setOpenNotificationMenu(false);
	};

	/**
	 * @function  handleHeaderMenuClick
	 * @param {Object} e | Component Click event
	 * @param {Number} actionType | It will have 1 or 2, 1 === Mark All Read & 2 === Delete All
	 */
	const handleHeaderMenuClick = (e, actionType) => {
		e.preventDefault();
		e.stopPropagation();
		/* notificationTabId { All = 1, Unread = 2, Actionable = 3 } */
		const notificationTabId = getNotificationTabId();
		if (actionType === 1) {
			// Mark All Read
			readNotificationAPICall('All', notificationData, notificationTabId);
		} else if (actionType === 2) {
			// Make APi call for All Notification Read
			if (notificationData && notificationData.length > 0) {
				// Delete All
				let message = t('notificationDeleteMessage');
				const data = {
					records: notificationData,
					notificationTabId,
				};
				if (notificationTabId === 1) {
					message = t('notificationAllDeleteMessage');
					data.tab = 'All';
				} else if (notificationTabId === 2) {
					message = t('notificationUnreadDeleteMessage');
					data.tab = 'Unread';
				} else if (notificationTabId === 3) {
					message = t('notificationActionDeleteMessage');
					data.tab = 'Actionable';
				}
				setModalOpen({ open: true, message: message, type: t('deleteAllLevel'), data });
			} else {
				setLoader(false);
				const message = t('NoRecord');
				enqueueSnackbar(message || '', {
					variant: 'success',
					autoHideDuration: 5000,
					transitionDuration: { enter: 500, exit: 500 },
					// TransitionComponent: Collapse,
				});
			}
		}
	};
	const readNotificationAPICall = (type, data, notificationTabId) => {
		if (type === 'Single') {
			// Make APi call for Single Notification Read
			callReadNotificationAPI(data);
		} else if (type === 'All') {
			// Make APi call for All Notification Read
			const isRead = true;
			const queryParams = `notificationTabId=${notificationTabId}&isRead=${isRead}`;
			readNotificationAPIHandler(queryParams, [], type, isRead);
		}
	};

	const callReadNotificationAPI = (item) => {
		if (item && Boolean(item.id)) {
			const isRead = !item.isRead;
			const notificationTabId = getNotificationTabId();
			const queryParams = `notificationTabId=${notificationTabId}&isRead=${isRead}`;
			readNotificationAPIHandler(queryParams, [item.id], 'Single', isRead);
		}
	};

	const readNotificationAPIHandler = (queryParams, ids, type, isRead) => {
		setLoader(true);
		readSingleNotification(queryParams, ids).then((resp) => {
			if (resp) {
				if (resp && resp?.data && resp?.data?.status === 200) {
					let messageContent = isRead ? t('readNotification') : t('unReadNotification');
					let variant = 'success';
					const { entity, messageList } = resp?.data;
					if (entity) {
						const { allUnreadCount, message } = entity;
						setUnReadMessageCount(allUnreadCount || 0);
						messageContent = type === 'All' ? message : messageContent;
					} else if (type === 'All' && isRead) {
						const errorMsg = messageList ? Object.values(messageList).join('') : messageContent;
						variant = 'error';
						messageContent = isBulkDelete && errorMsg;
					}
					if (type === 'All') {
						enqueueSnackbar(messageContent, {
							variant,
							autoHideDuration: 5000,
							transitionDuration: { enter: 500, exit: 500 },
							// TransitionComponent: Collapse,
						});
					}
				}

				if (type !== 'All') {
					const updatedData =
						notificationData && notificationData.length
							? [...notificationData].map((rec) => {
									if (rec.id === ids[0]) {
										rec.isRead = isRead;
									}
									return rec;
							  })
							: [];
					setNotificationData([...updatedData]);
				} else {
					const updatedData =
						notificationData && notificationData.length
							? [...notificationData].map((rec) => {
									rec.isRead = isRead;
									return rec;
							  })
							: [];
					setNotificationData([...updatedData]);
				}
				setLoader(false);
			}
		});
	};

	const handleRequestOneOnOne = (item, type) => {
		const itemArray = item.url && item.url.split('/');
		const krId = itemArray[2] ? parseInt(itemArray[2]) : 0;
		const data = {
			requestId: krId,
			requestType: 2,
			requestedTo: [item.notificationsBy],
			requestedFrom: userDetail.employeeId,
			onetoOneRemark: t('requestOneOnOneText'),
		};
		setLoader(true);
		setRequestOneOnOne({
			buttonId: item.id,
		});
		handleReadNotification(item);
		props
			.requestOneToOneFeedback(data)
			.then((res) => {
				enqueueSnackbar(t('requestOneOnOneForSuccess'), {
					variant: 'success',
					autoHideDuration: 5000,
				});
				setLoader(false);
				if (value === 2) {
					fetchNotifications(0, 2);
				}
			})
			.catch((err) => {
				setLoader(false);
				console.error(err);
			});
	};

	const GetNotificationList = ({ tabType, listData }) => {
		return listData && listData.length ? (
			listData.map((item) => (
				<ListItem
					key={`notification${item.id}`}
					id={`notification-${item.id}`}
					style={{ cursor: 'pointer' }}
					className={!item.isRead ? 'unread-message' : ''}
				>
					{item.imagePath ? (
						<Avatar id={`notification-avatar-${item.id}`} className='avatar-type3' src={item.imagePath} />
					) : (
						<Avatar id={`notification-avatar-${item.id}`} className='avatar-type3'>
							{getUserName(item)}
						</Avatar>
					)}
					{checkRequestOneOnOneType(item) ? (
						<ListItemText onClick={(e) => readNotifications(e, item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							<Box className='notification-actions'>
								<Button
									className='btn-primary'
									id={`accept-btn-${item.id}`}
									color='primary'
									onClick={(event) => requestOneOnOneAcceptOrReject(event, item, 1)}
								>
									{t('acceptLabel')}
								</Button>
								<Button
									className='btn-link'
									id={`decline-btn-${item.id}`}
									color='primary'
									onClick={(event) => requestOneOnOneAcceptOrReject(event, item, 0)}
								>
									{t('decline')}
								</Button>
							</Box>
							<Typography id={`created-on-${item.id}`} variant='h6'>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkPipNotification(item) || checkPipCommentsNotification(item) ? (
						<ListItemText onClick={(e) => handlePipNotification(item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							{item.url.includes('ChangeLeader') && (
								<Box className='notification-actions'>
									<Button className='btn-primary' color='primary' id={`view-okr-${item.id}`}>
										{t('viewOkr')}
									</Button>
								</Box>
							)}
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkPerformNotification(item) ? (
						<ListItemText onClick={(e) => handlePerformNotification(item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							{item.url.includes('ChangeLeader') && (
								<Box className='notification-actions'>
									<Button className='btn-primary' color='primary' id={`view-okr-${item.id}`}>
										{t('viewOkr')}
									</Button>
								</Box>
							)}
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkPerformanceGoalNotification(item) ? (
						<ListItemText onClick={(e) => handlePerformanceGoalNotification(item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkRecognizeNotification(item) ? (
						<ListItemText onClick={(e) => handleRecognizeNotification(item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkUserdeletedNotification(item) ? (
						<ListItemText onClick={(e) => readNotifications(item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkKrAssignment(item) && !isLocked ? (
						<ListItemText onClick={(e) => readNotifications(e, item, true)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							<Box className='notification-actions'>
								{/* <Button
									className='btn-primary'
									id={`accept-btn-${item.id}`}
									color='primary'
									onClick={() => handleRequestOneOnOne(item, 'accept')}
									disabled={item.notificationTypeId === Enums.TWENTY_NINE || requestOneOnOne?.buttonId === item.id}
								>
									{t('requestOnetoOne')}
								</Button> */}
								{/* <Button
									className='btn-primary'
									id={`accept-btn-${item.id}`}
									color='primary'
									onClick={() => requestKrAssignment(item, 'accept')}
								>
									{t('acceptLabel')}
								</Button>
								<Button
									className='btn-link'
									id={`decline-btn-${item.id}`}
									color='primary'
									onClick={() => requestKrAssignment(item, 'decline')}
								>
									{t('decline')}
								</Button> */}
							</Box>
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : handleNotesDifferentCycle(item) ? (
						<ListItemText onClick={(e) => handleNotesDifferentCycleItem(e, item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							{item.url.includes('ChangeLeader') && (
								<Box className='notification-actions'>
									<Button className='btn-primary' color='primary' id={`view-okr-${item.id}`}>
										{t('viewOkr')}
									</Button>
								</Box>
							)}
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : handleConversationsDifferentCycle(item) ? (
						<ListItemText onClick={(e) => handleConversationsDifferentCycleItem(e, item)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							{item.url.includes('ChangeLeader') && (
								<Box className='notification-actions'>
									<Button className='btn-primary' color='primary' id={`view-okr-${item.id}`}>
										{t('viewOkr')}
									</Button>
								</Box>
							)}
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : checkRequestOneOnOneIfAcceptedOrDeclined(item) ? (
						<ListItemText onClick={(e) => readNotifications(e, item, true)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							<Box className='notification-actions'>
								{item.notificationTypeId === 6 ? (
									<Button className='btn-primary' id={`accept-btn-${item.id}`} color='primary' disabled>
										{t('NotificationAccepted')}
									</Button>
								) : item.notificationTypeId === 7 && item.url && item.url.toLowerCase().includes('krassignment') ? (
									<Button className='btn-primary' id={`decline-btn-${item.id}`} color='primary' disabled>
										{t('NotificationDeclined')}
									</Button>
								) : item.notificationTypeId === 8 && item.url && item.url.toLowerCase().includes('krassignment') ? (
									<Button className='btn-primary' id={`decline-btn-${item.id}`} color='primary' disabled>
										{t('NotificationRejectedDeleted')}
									</Button>
								) : (
									<></>
								)}
							</Box>
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					) : (
						<ListItemText onClick={(e) => readNotifications(e, item, true)}>
							<Typography
								id={`notification-msg-${item.id}`}
								component='p'
								dangerouslySetInnerHTML={{ __html: item.notificationsMessage }}
							/>
							{item.url.includes('ChangeLeader') && (
								<Box className='notification-actions'>
									<Button className='btn-primary' color='primary' id={`view-okr-${item.id}`}>
										{t('viewOkr')}
									</Button>
								</Box>
							)}
							<Typography variant='h6' id={`created-on-${item.id}`}>
								{getTime(item.updatedOn)}
							</Typography>
						</ListItemText>
					)}
					<Box className='notification-action-btn' id={`notification-action-${item.id}`}>
						<Tooltip arrow title={!item.isRead ? `${t('markAsReadLevel')}` : `${t('markAsUnReadLevel')}`}>
							<IconButton
								id={`mark-read-${item.id}`}
								className='action-btn'
								role='button'
								onClick={(e) => markAsReadNotification(e, item)}
							>
								<MarkReadIcon />
							</IconButton>
						</Tooltip>
						<Tooltip arrow title={`${t('delete')}`}>
							<IconButton
								id={`notification-delete-${item.id}`}
								className='action-btn'
								role='button'
								onClick={(e) => deleteNotification(e, item.id)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</ListItem>
			))
		) : loader ? (
			<></>
		) : (
			<ListItem className='no-notification'>
				<Box className='no-notification-msg'>
					<Box className='no-msg'>
						<Typography component='p'>{t('noMessage')}</Typography>
						<img src={NoNotificationImage} alt={'notification-msg'} />
					</Box>
				</Box>
			</ListItem>
		);
	};

	return (
		<Box className='notification-area' id='notification-area'>
			<Tooltip title={t('notificationLabel')} arrow>
				<IconButton
					className={'notification-btn'}
					aria-label='open notification'
					onClick={(e) => {
						handleNotificationOpen(e);
					}}
					id='notification-btn'
				>
					{props.notificationHighLight ? (
						<Badge
							variant={'dot'}
							className='chat-bubble'
							color='secondary'
							overlap='rectangular'
							invisible={
								!props.notificationHighLight &&
								parseInt(unReadMessageCount) + parseInt(props?.userUnreadNotificationResult.alertCount) === 0
							}
						>
							{/* <NotificationIcon /> */}
							<AnimatedIcon
								className=''
								width='16'
								type='image/svg+xml'
								alt='UserImage'
								imagePath={NotificationImage}
							/>
						</Badge>
					) : (
						<>
							<NotificationIcon />
						</>
					)}
				</IconButton>
			</Tooltip>
			<Drawer className='notification-drawer' anchor='right' open={openNotification} onClose={handleNotificationClose}>
				<IconButton
					className='drawer-close-btn'
					id='drawer-close-btn'
					aria-label='open notification'
					onClick={handleNotificationClose}
				>
					<RightArrowIcon />
				</IconButton>

				<Box className='notification-tabs-area' id='notification-tabs-area'>
					<Box className='notification-tabs'>
						<Box className='notification-tabs-left'>
							<Tabs value={value} onChange={handleTabChange} aria-label='notification tabs'>
								<Tab
									label={t('AllTabLabel')}
									{...a11yProps(0)}
									onClick={(e) => {
										setNotificationData([]);
										setShowLoadMore(false);
										fetchNotifications(0, 0);
									}}
									id='all-tab'
								/>
								<Tab
									label={t('unreadLabel')}
									{...a11yProps(1)}
									onClick={(e) => {
										setNotificationData([]);
										setShowLoadMore(false);
										fetchNotifications(0, 1);
									}}
									id='unread-tab'
								/>
								<Tab
									label={t('actionableLabel')}
									{...a11yProps(2)}
									onClick={(e) => {
										setNotificationData([]);
										setShowLoadMore(false);
										fetchNotifications(0, 2);
									}}
									id='actionable-tab'
								/>
							</Tabs>
						</Box>
						<Box className='notification-tabs-right'>
							<OKRButton className='kebab-icon' icon={<KebabIcon />} handleClick={handleNotificationMenuToggle} />
							<Popper
								className='popper-menu-list notification-menu-list'
								open={openNotificationMenu}
								anchorEl={anchorEl}
								placement='bottom-start'
								transition
							>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<Paper>
											<ClickAwayListener onClickAway={handleNotificationMenuClose}>
												<MenuList id='notification-menu-list-grow'>
													<MenuItem onClick={(e) => handleHeaderMenuClick(e, 1)}>
														<MarkReadIcon />
														{t('markAllReadLevel')}
													</MenuItem>
													<MenuItem onClick={(e) => handleHeaderMenuClick(e, 2)}>
														<DeleteIcon /> {t('deleteAllLevel')}
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Box>
						{loader ? (
							<Box className='loader-area'>
								<LinearLoader data={props.listNotificationSuccess} />
							</Box>
						) : (
							<></>
						)}
					</Box>

					<Box className='notification-tabs-panel'>
						<NotificationTabPanel value={value} index={0} id='notification-tab-content'>
							{notificationData && notificationData.length ? (
								<Typography className='unread-message-count'>
									{t('unreadMessages', { unreadCount: unReadMessageCount || 0 })}
								</Typography>
							) : (
								<></>
							)}
							<List autoFocusItem={openNotification} id='menu-list-grow'>
								<GetNotificationList tabType={0} listData={notificationData} />

								{notificationData && notificationData.length && showLoadMore ? (
									<ListItem className='load-more-link'>
										<ListItemText
											onClick={(e) => {
												fetchNotifications(pageIndex || 0, 0);
											}}
											id='notification-load-more'
										>
											{loader ? (
												<Box className='spinner'>
													<Box className='bounce1'></Box>
													<Box className='bounce2'></Box>
													<Box className='bounce3'></Box>
												</Box>
											) : (
												t('Load More')
											)}
										</ListItemText>
									</ListItem>
								) : (
									<></>
								)}
							</List>
						</NotificationTabPanel>
						<NotificationTabPanel value={value} index={1} id='unread-tab-content'>
							{notificationData && notificationData.length ? (
								<Typography className='unread-message-count'>
									{t('unreadMessages', { unreadCount: unReadMessageCount || 0 })}
								</Typography>
							) : (
								<></>
							)}
							<List autoFocusItem={openNotification} id='menu-list-grow'>
								<GetNotificationList tabType={1} listData={notificationData} />
								{notificationData && notificationData.length && showLoadMore ? (
									<ListItem className='load-more-link'>
										<ListItemText
											onClick={(e) => {
												fetchNotifications(pageIndex || 0, 1);
											}}
											id='notification-load-more'
										>
											{loader ? (
												<Box className='spinner'>
													<Box className='bounce1'></Box>
													<Box className='bounce2'></Box>
													<Box className='bounce3'></Box>
												</Box>
											) : (
												t('Load More')
											)}
										</ListItemText>
									</ListItem>
								) : (
									<></>
								)}
							</List>
						</NotificationTabPanel>
						<NotificationTabPanel value={value} index={2} id='unread-tab-content'>
							{notificationData && notificationData.length ? (
								<Typography className='unread-message-count'>
									{t('unreadMessages', { unreadCount: unReadMessageCount || 0 })}
								</Typography>
							) : (
								<></>
							)}
							<List autoFocusItem={openNotification} id='menu-list-grow'>
								<GetNotificationList tabType={2} listData={notificationData} />
								{notificationData && notificationData.length && showLoadMore ? (
									<ListItem className='load-more-link'>
										<ListItemText
											onClick={(e) => {
												fetchNotifications(pageIndex || 0, 2);
											}}
											id='notification-load-more'
										>
											{loader ? (
												<Box className='spinner'>
													<Box className='bounce1'></Box>
													<Box className='bounce2'></Box>
													<Box className='bounce3'></Box>
												</Box>
											) : (
												t('Load More')
											)}
										</ListItemText>
									</ListItem>
								) : (
									<></>
								)}
							</List>
						</NotificationTabPanel>
					</Box>
				</Box>

				{modalOpen && modalOpen.open && !notSameCycleId && (
					<AlertDialog
						module='deleteMyObjective'
						message={modalOpen?.message || t('notificationDeleteMessage')}
						handleCloseModal={handleCloseModal}
						modalOpen={modalOpen.open}
					/>
				)}

				{modalOpen && notSameCycleId && (
					<AlertDialog
						message={t('notSameCycle', { notSameCycle: `${quarterSymbol}, ${cycleYear}` })}
						handleCloseModal={handleCloseModal}
						modalOpen={modalOpen}
						isCancel={false}
					/>
				)}
			</Drawer>
			{feedbackRedirection && (
				<Feedback
					objId={0}
					objective={feedbackData ? feedbackData.entity : {}}
					feedbackDetailId={feedbackData ? feedbackData.feedbackDetailId : 0}
					feedbackRequestId={feedbackData ? feedbackData.feedbackRequestId : 0}
					{...props}
					userDetail={userDetail}
					feedbackRedirection={feedbackRedirection}
					showGiveFeedback={feedbackType}
					notificationBy={notificationBy}
					resetNotificationData={resetNotificationData}
					setFeedback={() => {}}
					feedbackOnData={feedbackData}
				/>
			)}
			{pfRedirection && Boolean(showPersonalFeedback) && (
				<PersonalFeedbackMenu
					{...props}
					loggedInUser={true}
					feedbackRedirection={pfRedirection}
					showPersonalFeedback={showPersonalFeedback}
					userDetail={userDetail}
					resetPFData={resetNotificationData}
					feedbackData={feedbackData}
					setFeedbackData={setFeedbackData}
				/>
			)}
			{openOneOnOneDrawer && (
				<OneOnOneDrawer
					{...props}
					openOneOnOneDrawer={openOneOnOneDrawer}
					handleDrawerClose={handleDrawerClose}
					oneOnOneEntity={oneOnOneEntity}
				/>
			)}
		</Box>
	);
}
