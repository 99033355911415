import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Select, { components, StylesConfig } from 'react-select';
import _ from 'lodash';
import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../config/utils';
import { LeaderIcon, TeamCountIcon } from '../../../config/svg/formElementIcons';
import { DisableIconSmall, StarIcon } from '../../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';

export const MultiSelect: React.FC<any> = (props) => {
	const {
		optionsList,
		selectedOptions,
		customFilter,
		key,
		onSelectOption,
		fetchAsyncData,
		performSearch,
		placeHolder,
		noOptionsMessage,
		isSingleSelection,
		closeMenuOnSelect,
		labelTemplate,
		showNoOptions,
		selectClassName,
		selectClassNamePrefix,
		isMulti,
		helperText,
		blurCalled,
		disableSearch,
		id,
		disabled,
		onInputFocus,
		blurInputOnSelect = false,
		isLoadMore = false,
		setOptionsList,
		hideLoggedIn,
		loggedInUserIds,
		setEmptyRecords = () => {},
		emptyRecords = {},
		allReviewersList = [],
		customQueryString,
	} = props;
	const { t } = useTranslation();

	const [paggingInfo, setPaggingInfo] = useState<any>({ pageIndex: 1, pageSize: 6, totalRecords: 0, totalPages: 1 });
	const [apiLoading, setApiLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');

	const formatOptionLabel = (props: any, context: any) => {
		const {
			value,
			label,
			emailId,
			imagePath,
			secondaryText,
			count,
			isIndividualPermission,
			backGroundColorCode,
			colorCode,
			classList,
			userCount = 0,
		} = props;
		if (context && context.context === 'value' && labelTemplate === 'teamAvatarLabel') {
			return (
				<Box className='search-user-result' id={`${'option-' + value}`}>
					<Box className='search-user-img'>
						{imagePath ? (
							<Avatar src={imagePath} />
						) : (
							<Avatar
								style={{
									backgroundColor: getRandomBackgroundColor(backGroundColorCode),
									color: getRandomColor(colorCode),
								}}
							>
								{getUserName({ fullName: label, firstName: '', lastName: '' })}
							</Avatar>
						)}
					</Box>
					<Box className='search-user-info'>
						{label?.length > 25 ? (
							<Tooltip arrow title={label} aria-label='Team Name'>
								<Typography
									variant='h4'
									style={{
										color: getRandomColor(colorCode),
									}}
								>
									{label.substring(0, 25)}...
								</Typography>
							</Tooltip>
						) : (
							<Typography
								variant='h4'
								style={{
									color: getRandomColor(colorCode),
								}}
							>
								{label}
							</Typography>
						)}
					</Box>
				</Box>
			);
		}
		switch (labelTemplate) {
			case 'onlyLabel':
				return (
					// add class here 'chip-star when star icon will be show'
					<Box className={isIndividualPermission ? 'chip-star role-info' : 'role-info'}>
						<Typography variant='h4'>{label}</Typography>
						{isIndividualPermission && (
							<sup>
								<StarIcon />
							</sup>
						)}
					</Box>
				);
			case 'avatarLabel':
				return (
					<Box className={`search-user-result ${classList}`}>
						<Box className='search-user-img'>
							{imagePath ? (
								<Avatar src={imagePath} />
							) : (
								<Avatar>{getUserName({ fullName: label, firstName: '', lastName: '' })}</Avatar>
							)}
						</Box>
						<Box className='search-user-info'>
							<Typography variant='h4'>{label}</Typography>
							{secondaryText ? <Typography variant='subtitle2'>{secondaryText}</Typography> : <></>}
						</Box>
					</Box>
				);
			case 'teamAvatarLabel':
				return (
					<Box className='search-user-result'>
						<Box className='search-user-img'>
							{imagePath ? (
								<Avatar src={imagePath} />
							) : (
								<Avatar
									style={{
										backgroundColor: getRandomBackgroundColor(backGroundColorCode),
										color: getRandomColor(colorCode),
									}}
								>
									{getUserName({ fullName: label, firstName: '', lastName: '' })}
								</Avatar>
							)}
						</Box>
						<Box className='search-user-info'>
							<Typography variant='h4'>{label}</Typography>
							{secondaryText ? (
								<List component='ul' disablePadding>
									<ListItem>
										<Typography variant='subtitle2'>
											<TeamCountIcon />
											{count}
										</Typography>
									</ListItem>
									<ListItem>
										<Typography variant='subtitle2'>
											<LeaderIcon />
											{secondaryText}
										</Typography>
									</ListItem>
								</List>
							) : (
								<></>
							)}
						</Box>
					</Box>
				);
			case 'numberUserLabel':
				return (
					<div className='selected-chip'>
						<div className='primary-text'>{label}</div>
						<div className='secondary-text'>{userCount + ' Users'}</div>
					</div>
				);
			case 'selectGoalReviewCycle':
				return (
					<div className='selected-chip'>
						<div className='primary-text'>{label}</div>
						<div className='secondary-text'>{userCount + ' Users'}</div>
					</div>
				);
			default:
				return (
					<div className='selected-chip'>
						<div className='primary-text'>{label}</div>
						<div className='secondary-text'>{emailId}</div>
					</div>
				);
			/*return (
					<div className='selected-chip'>
						<div className='col-1'>Name</div>
						<div className='col-2'>Timeframe</div>
						<div className='col-3'>Setting Duration</div>
						<div className='col-4'>Users</div>
					</div>
				);*/
		}
	};
	const performInternalSearch = async (
		finder = '',
		pageIndex = 1,
		pageSize = 6,
		append = false,
		allReviewersListVal = [],
		customQString = ''
	) => {
		if (!isLoadMore) {
			performSearch(finder, allReviewersListVal, customQString);
		} else {
			performLoadMoreSearch(finder, pageIndex, pageSize, append, customQString);
		}
	};
	const performLoadMoreSearch = async (
		finder = '',
		pageIndex = 1,
		pageSize = 6,
		append = false,
		customQString = ''
	) => {
		setApiLoading(true);
		const response = await performSearch(
			'finder=' + finder + '&pageIndex=' + pageIndex + '&pageSize=' + pageSize + customQString,
			finder,
			pageIndex,
			pageSize,
			allReviewersList
		);
		if (response.data.status === 200) {
			let options = Object.assign([], optionsList);
			let optionId = 0;
			let responseList = response.data.entityList ? response.data.entityList : [];
			if (hideLoggedIn) {
				responseList = responseList.filter((item: any) => !loggedInUserIds.includes(item.employeeId));
			}
			let finalOptions = responseList.map((userData: any) => {
				if (optionId === 0) {
					optionId = userData.teamId ? userData.teamId : userData.employeeId;
				}
				return {
					...userData,
					label: userData.searchType === 2 ? userData.teamName : userData.firstName + ' ' + userData.lastName,
					value: userData.searchType === 2 ? userData.teamId : userData.employeeId,
					secondaryText: userData.searchType === 2 ? 'Leader Name: ' + userData.teamHeadName : userData.emailId,
					imagePath: userData.searchType === 2 ? userData.logoImagePath : userData.imagePath,
					emailId: userData.emailId,
					classList: userData.searchType === 2 ? 'user-team-chip' : '',
				};
			});
			if (append === true) {
				finalOptions = options.concat(finalOptions);
			} else {
				if (!finalOptions || finalOptions.length === 0) {
					setEmptyRecords({ ...emptyRecords, reportingTo: true });
				}
			}
			setOptionsList(finalOptions);
			setPaggingInfo(response.data.paggingInfo);

			setTimeout(() => {
				const selectedEl = document.getElementById('option-' + optionId);
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}, 6);
			setApiLoading(false);
		} else if (response.data.status === 204) {
			setApiLoading(false);
			setOptionsList([]);
			setPaggingInfo({ pageIndex: 1, pageSize: 6, totalRecords: 0, totalPages: 1 });
			setEmptyRecords({ ...emptyRecords, reportingTo: true });
		}
	};

	const loadMore = () => {
		performInternalSearch(searchText, paggingInfo.page + 1, paggingInfo.pageSize, true, allReviewersList);
	};

	const handleSearchThrottled = (value: any, allReviewersListVal: any, customQString: any) => {
		delayedQuery(value, allReviewersListVal, customQString);
	};
	const delayedQuery = useCallback(
		_.debounce((q, allReviewersListVal, customQString) => _handleSearch(q, allReviewersListVal, customQString), 500),
		[]
	);
	const _handleSearch = async (value: string, allReviewersListVal: any, customQString = '') => {
		setSearchText(value);
		performInternalSearch(value, 1, paggingInfo.pageSize, false, allReviewersListVal, customQString);
	};

	const updateSelection = (selectedVal: any, actionMeta: any) => {
		if (actionMeta.action === 'remove-value' && actionMeta.removedValue?.isNotDeletable) {
			return false;
		}
		if (isSingleSelection === true) {
			if (selectedOptions && selectedOptions.length > 0) {
				onSelectOption(selectedVal.filter((value: any) => value.value !== selectedOptions[0].value));
			} else {
				onSelectOption(selectedVal);
			}
		} else {
			if (isMulti === false) {
				onSelectOption([selectedVal]);
			} else {
				onSelectOption(selectedVal);
			}
		}
	};

	const colourStyles: StylesConfig<any, true> = {
		multiValue: (styles: any, { data }: any) => {
			return {
				...styles,
				borderRadius: '24px',
				color: getRandomColor(data.colorCode),
				backgroundColor: `${getRandomBackgroundColor(data.backGroundColorCode)} !important`,
			};
		},

		multiValueLabel: (styles: any, { data }: any) => ({
			...styles,
			color: getRandomColor(data.colorCode),
		}),

		multiValueRemove: (styles: any, { data }: any) => ({
			...styles,
			color: getRandomColor(data.colorCode),
			':hover': {
				color: `${getRandomColor(data.colorCode)} !important`,
			},
		}),
	};
	const MenuList = (props1: any) => {
		const isNotDeletable = props1?.data?.isNotDeletable;
		return isNotDeletable ? (
			<Tooltip arrow title={`${t('disableUserDeleteMsg')}`} className='delete-icon-select'>
				<span
					{...props1}
					styles={{
						color: getRandomColor(props1?.data?.colorCode),
					}}
				>
					<DisableIconSmall />
				</span>
			</Tooltip>
		) : (
			<components.MenuList {...props1}>
				<Box className='menu-list'>{props1.children}</Box>
				{isLoadMore && !apiLoading && paggingInfo.totalPages > 1 && paggingInfo.page !== paggingInfo.totalPages ? (
					<Box className='dropdown-footer'>
						<OKRButton className='btn-link' handleClick={loadMore} text={t('loadMore')}></OKRButton>
					</Box>
				) : (
					<></>
				)}
			</components.MenuList>
		);
	};
	return (
		<Box className={`width-100 `}>
			<Select
				key={key}
				styles={labelTemplate === 'teamAvatarLabel' ? colourStyles : undefined}
				id={id || ''}
				isMulti={isMulti}
				isDisabled={disabled || false}
				closeMenuOnSelect={closeMenuOnSelect === true}
				options={optionsList}
				value={selectedOptions}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeHolder}
				onChange={updateSelection}
				onInputChange={(value) => {
					setEmptyRecords({ ...emptyRecords, reportingTo: false });
					handleSearchThrottled(value, allReviewersList, customQueryString);
				}}
				filterOption={(option: any, searchText: any) => {
					if (fetchAsyncData === true) {
						return true;
					} else {
						return customFilter(option, searchText);
					}
				}}
				onBlur={() => {
					blurCalled && blurCalled();
				}}
				blurInputOnSelect={blurInputOnSelect}
				noOptionsMessage={() => (showNoOptions === true ? noOptionsMessage : null)}
				className={`${selectClassName} ${optionsList.length > 0 ? 'menu-open' : ''}`}
				classNamePrefix={selectClassNamePrefix}
				isClearable={false}
				isSearchable={!disableSearch}
				inputId={`${id || 'input-id'}`}
				instanceId={`${id || 'input-placeholder'}`}
				onFocus={() => {
					onInputFocus && onInputFocus();
				}}
				components={{
					MenuList,
				}}
				//menuIsOpen={true}
			/>
			{helperText && helperText !== '' ? <p className='MuiFormHelperText-root Mui-error'>{helperText}</p> : <></>}
		</Box>
	);
};
