export const generalPermissionModule = 1;
export const teamsPermissionModule = 2;
export const userPermissionModule = 3;
export const rolePermissionModule = 4;
export const coachPermissionModule = 5;

export const createOkrs = 1;
export const editOkrs = 2;
export const assignOkr = 3;
export const allowToAddContributorForOkr = 4;
export const feedbackModule = 5;
export const OneToOneModule = 6;
export const viewOrganizationManagementPage = 7;
export const createTeams = 8;
export const editMainOrganization = 9;
export const editTeams = 10;
export const deleteTeams = 11;
export const viewUserManagementPage = 12;
export const addNewUsers = 13;
export const editUsers = 14;
export const deleteUsers = 15;
export const viewRoleManagement = 16;
export const addNewRole = 17;
export const editExistingRole = 18;
export const deleteRole = 19;
export const allowCreateOkrsOnBehalfOfAnotherPerson = 20;
export const impersonateUser = 21;
export const userWiseActivityReport = 26;
export const userWiseOkrProgressReport = 27;
export const directReportsFeedbackReport = 28;
export const performanceImprovementPlanReport = 30;
export const performanceAssessmentQuickStatusReports = 31;
export const performanceAssessmentDetailedStatusReports = 32;
export const userDetailsReport = 33;
export const oneOnOneReports = 34;
export const recognitionReports = 35;
export const checkInReports = 36;
export const goalCycleStatusReport = 37;
export const goalSettingReversalReport = 38;

export const supportModuleAlignOkr = 1;
export const supportModuleLearn = 2;
export const supportModulePerformAssessment = 3;
export const supportModuleRecognize = 4;
export const supportModuleEngage = 5;
export const supportModulePerformDevelopment = 6;
export const supportModuleAlignGoals = 7;
export const supportModuleLetterGeneration = 8;
export const feedbackReportPermission = 29;
