export enum Enums {
	/** -------- Objective ENUMS --------- */

	/** Number */
	ZERO = 0,
	ONE = 1,
	TWO = 2,
	THREE = 3,
	FOUR = 4,
	FIVE = 5,
	SIX = 6,
	TEN = 10,
	TWENTY_FIVE = 25,
	TWENTY_EIGHT = 28,
	TWENTY_NINE = 29,
	/** metricId default unit */
	NO_UNITS = 'NoUnits',
	PERCENTAGE = 'Percentage',
	/** Target Value */
	TARGET = 0,
	/** Default Goal Type */
	INDIVIDUAL = 'Individual',
	/** Default goalStatus Status Type */
	PUBLIC = 'Public',
	/** ---------- Objective ENUMS ENDS ----- */

	MAX_USER_SIZE = 999999,

	/** Confirm  dialog values*/
	DELETE_TEXT = 'Delete',
	UNLOCK_TEXT = 'Unlock',
	CONFIRM_TEXT = 'CONFIRM',
	CHANGE_TEXT = 'Change',
	METRIC_TEXT = 'Metric',

	/**  */
	ACTIVE = 'active',

	/** User team */
	USER_TEAM_SORTING = 'employeeTeamResponses.teamName',

	/** */
	DEFAULT_TYPE_CHAR = 1,
	/** Used to set Max input length for Current and Target */
	MAX_LENGTH_CURRENT_TARGET = 15,

	/** Status for API */
	STATUS_SUCCESS = 200,
	STATUS_BAD_REQUEST = 400,
	STATUS_SERVER_ERROR = 500,

	HIDE_SAVE_ANOTHER_BUTTON = 'true',

	KR_STATUS_PENDING = 1, //"Pending"
	KR_STATUS_ACCEPTED = 2, //"Accepted"
	KR_STATUS_DECLINED = 3, //"Declined"

	GOAL_STATUS_DRAFT = 1, //"DRAFT"
	GOAL_STATUS_PUBLIC = 2, //"PUBLIC"
	GOAL_STATUS_ARCHIVE = 3, // "ARCHIVE"

	skipOnboardingAction = 1,
	readyOnboardingAction = 2,
	MAX_LENGTH_ROLE_NAME = 12,
	USER_ROLE_ID = 4,
	LICENSE_THRESHOLD = 5,

	GLOBAL_SEARCH_PAGE_SIZE = 6,

	MAX_CADENCE_FOR_CHECKIN_NOTIFICATON = 30,
	MAX_PERIOD_FOR_EMAIL_TRIGGER = 30,
	GRACE_PERIOD = 1000,

	/** CHECKIN STATUS */
	STATUS_CHECKED_ID = 2,
	NOT_YET_CHECKED_IN = 3,
	CHECKED_IN_MISSED = 1,
	CHECKED_IN_NO_DATA = 4,
	CHECKED_NO_PERMISSION = 5,

	/** Cycle Status */
	FUTURE_CYCLE_ID = 3,
	CURRENT_CYCLE_ID = 2,
	PAST_CYCLE_ID = 1,

	/** Checkin visibility */
	CHECKIN_VISIBILITY_RM = 2, // Visible to Reporting Manager
	CHECKIN_VISIBILITY_ALL = 1, //Visible to all

	/** Praise status code */
	PRAISE_HIDE = 0,
	PRAISE_SEND = 1,
	PRAISE_SENT = 2,

	/** CHECKIN DATE STATUS */

	PREVIOUS_WEEK = -1,
	CURRENT_WEEK = 0,
	FUTURE_WEEK = 1,

	/** SignalR Topics*/
	topicAddContributor = 'TopicAddContributor',
	topicDeleteOKR = 'TopicDeleteOKR',
	topicConversationTag = 'TopicConversationTag',
	topicNotesTag = 'TopicNotesTag',
	topicNudgeTeam = 'TopicNudgeTeam',
	topicUpdateOKRDueDate = 'TopicUpdateOKRDueDate',
	topicOKRCoach = 'TopicOKRCoach',
	topicNudge = 'TopicNudge',
	topicAcceptOKR = 'TopicAcceptOKR',
	topicDeclineOKR = 'TopicDeclineOKR',
	topicGivePersonalFeedback = 'TopicGivePersonalFeedback',
	topicFeedbackOneOnOne = 'TopicFeedbackOneOnOne',
	topicPersonalFeedback = 'TopicPersonalFeedback',
	topicUpdateKrScore = 'TopicUpdateKrScore',
	topicUpdateKrValueAlignmentMap = 'TopicUpdateKrValueAlignmentMap',
	topicSignalR = 'TopicSignalR',
	topicRecognition = 'TopicRecognition', // For All User --- Refresh Recognize and Dashboard Tab
	topicRecognitionNotifications = 'TopicRecognitionNotifications', // notify bell Icon
	topicRequestOneToOne = 'TopicRequestOneToOne', // Notify bell Icon and refresh 1-on-1 and Dashboard,
	MAX_LENGTH_FEEDBACK_NAME = 25,
}

export enum EnumPerformanceScoreVisibilitySettings {
	Hide = 1,
	AfterSelfRating = 2,
	AfterManagerRating = 3,
	After360Rating = 4,
	AfterFinalRatingByManager = 5,
	AfterFinalScorePublishes = 6,
	View = 7,
}

export enum EnumPerformanceFormVisibilitySettings {
	Hide = 1,
	Show = 2,
}

export enum EnumEmailRecipients {
	DefaultOnly = 1,
	BULeader = 2,
	HRBP = 3,
	HRBuddy = 4,
	SkipManager = 5,
}

export enum EnumEmailModule {
	PIP = 1,
	Feedback = 2,
	RECOGNITION = 3,
	ONEonONE = 4,
	ThreeSixtyExperience = 5,
}

export enum EnumPerformanceGoalCycleStatus {
	Draft = 1,
	Submitted,
	Published,
	Completed,
}

export enum EnumAllowUsersToCreateFeedback {
	ReportingManager = 1,
	AllInlineReportingManagers = 2,
	SkipManager = 3,
	BUHead = 4,
	HRBP = 5
}

export enum EnumCommentModuleId {
	Recognisation = 1,
       ReplyComments = 2,
       PIPComments = 3,
       FeedbackComments = 4,
       PerformanceGoalComments = 5,
       PerformanceGoalReplyComments = 6,
       PerformanceGoalReviewComments = 7,
       PerformanceGoalReviewReplyComments = 8,
       PerformanceTeamReviewComments = 9,
       PerformanceTeamReviewReplyComments = 10,
}
export enum EnumLikeModule {
	CommentsLike = 3,
    CommentReplyLike = 4,
    CommentsGoalLike = 5,
    CommentReplyGoalLike = 6,
    TaskConversation = 7,
    CommentsReviewGoalLike = 8,
    CommentReplyReviewGoalLike = 9,
    CommentsTeamReviewLike = 10,
    CommentReplyTeamReviewLike = 11,
}