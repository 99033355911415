import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AlertInfoIcon } from '../../config/svg/CommonSvg';
import { useSelector } from 'react-redux';
import { checkSupportPermission } from '../../config/utils';
import { supportModuleAlignGoals } from '../Admin/AdminConstant';

export const PerformanceDevTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;
	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`performance-dev-tabpanel-${index}`}
			aria-labelledby={`performance-dev-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

PerformanceDevTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `performance-dev-tab-${index}`,
		'aria-controls': `performance-dev-tabpanel-${index}`,
	};
}

export const PerformanceDevTabs: React.FC<any> = (props) => {
	const { tabSelected = 0, handleTabChange } = props;
	const { pipAlert, feedbackAlert } = useSelector((state: any) => state.performanceImprovementPlanReducer);

	const { t } = useTranslation();

	return (
		<Tabs className='okr-tabs' value={tabSelected} onChange={handleTabChange} aria-label='perform Tabs'>
			{checkSupportPermission(supportModuleAlignGoals) && (
				<Tab
					value={0}
					label={
						<>
							<span dangerouslySetInnerHTML={{ __html: t('threeSixetyExperience') }}></span>
							<Tooltip
								arrow
								title={
									feedbackAlert?.isNewComment && feedbackAlert?.isTimeFrameAlert ? (
										<>
											{t('timeFramePassed')} <br /> {t('newComment')}
										</>
									) : feedbackAlert?.isNewComment ? (
										t('newComment')
									) : feedbackAlert?.isTimeFrameAlert ? (
										t('timeFramePassed')
									) : (
										<></>
									)
								}
							>
								{
									<Badge
										className='badge-icon'
										invisible={(feedbackAlert && !feedbackAlert.isThreeSixtyExperienceNewComment) || tabSelected === 0}
										overlap='rectangular'
										badgeContent={<AlertInfoIcon />}
									></Badge>
								}
							</Tooltip>
						</>
					}
					{...a11yProps(0)}
				/>
			)}

			<Tab
				value={1}
				label={
					<>
						{t('feedbackLabel')}
						<Tooltip
							arrow
							title={
								feedbackAlert?.isNewComment && feedbackAlert?.isTimeFrameAlert ? (
									<>
										{t('timeFramePassed')} <br /> {t('newComment')}
									</>
								) : feedbackAlert?.isNewComment ? (
									t('newComment')
								) : feedbackAlert?.isTimeFrameAlert ? (
									t('timeFramePassed')
								) : (
									<></>
								)
							}
						>
							{
								<Badge
									className='badge-icon'
									invisible={
										(feedbackAlert && !feedbackAlert?.isNewComment && !feedbackAlert?.isTimeFrameAlert) ||
										tabSelected === 1
									}
									overlap='rectangular'
									badgeContent={<AlertInfoIcon />}
								></Badge>
							}
						</Tooltip>
					</>
				}
				{...a11yProps(1)}
			/>
			<Tab
				label={
					<>
						{t('prsImprovementPlan')}
						<Tooltip
							arrow
							title={
								pipAlert?.isNewComment && pipAlert?.isTimeFrameAlert ? (
									<>
										{t('timeFramePassed')} <br /> {t('newComment')}
									</>
								) : pipAlert?.isNewComment ? (
									t('newComment')
								) : pipAlert?.isTimeFrameAlert ? (
									t('timeFramePassed')
								) : (
									<></>
								)
							}
						>
							{
								<Badge
									className='badge-icon'
									invisible={(pipAlert && !pipAlert?.isNewComment && !pipAlert?.isTimeFrameAlert) || tabSelected === 2}
									overlap='rectangular'
									badgeContent={<AlertInfoIcon />}
								></Badge>
							}
						</Tooltip>
					</>
				}
				value={2}
				{...a11yProps(2)}
			/>
		</Tabs>
	);
};
