import { deleteRequest, getRequest, postRequest, putRequest, uploadFile } from '../config/auth';
import {
	PIP_NOMINEE_SEARCH,
	PIP_CREATE_PIP_PLAN,
	UPLOAD_CONVERSATION_FILES,
	PIP_VIEW_PIP_PLAN,
	PIP_SAVE_COMMENT,
	PIP_DELETE_COMMENT,
	PIP_GET_COMMENT,
	PIP_GET_REVIEWER_DATE,
	PIP_RESOLVE_STATUS,
	PIP_RESOLVE_PLAN_STATUS,
	PIP_MY_LISTING,
	PIP_OTHERS_LISTING,
	PIP_CHECK_NOMINEE_EXISTS,
	PIP_PLAN_REASON,
	PIP_ALERT,
	FEEDBACK_MY_LISTING,
	NOMINEE_FEEDBACK_LIST,
	FEEDBACK_VIEW_PIP_PLAN,
	FEEDBACK_OTHERS_LISTING,
	FEEDBACK_CATEGORY,
	FEEDBACK_STATUS,
	FEEDBACK_TYPE,
	POST_FEEDBACK,
	FEEDBACK_RESOLVE_STATUS,
	FEEDBACK_CREATE_FEEDBACK_PLAN,
	FEEDBACK_ALERT,
	MULTI_UPLOAD_CONVERSATION_FILES,
	DELETE_UPLOADED_CONVERSATION,
	FEEDBACK_PLAN_REASON,
	FEEDBACK_PERMISSION,
	FEEDBACK_PERMISSION_SEARCH,
	PIP_DETAILS_PDF,
} from '../config/api-url';

export const getPIPNomineeSearchAPI = (data) => getRequest(`${PIP_NOMINEE_SEARCH}?${data}`, data);
export const createPIPPlanAPI = (data) => postRequest(`${PIP_CREATE_PIP_PLAN}`, data);
export const updatePIPPlanAPI = (data) => putRequest(`${PIP_CREATE_PIP_PLAN}`, data);
export const uploadPIPDocumentAPI = (data, type) => postRequest(`${UPLOAD_CONVERSATION_FILES}?type=${type}`, data);
export const uploadPIPMultiDocumentAPI = (data, type) => postRequest(`${MULTI_UPLOAD_CONVERSATION_FILES}${type}`, data);
// export const uploadPIPMultiDocumentAPI = (data, type) =>
// uploadFile(`${MULTI_UPLOAD_CONVERSATION_FILES}?type=${type}`, data);
export const getPIPListing = (data) => postRequest(`${PIP_MY_LISTING}`, data);
export const getOtherPIPListing = (data) => postRequest(`${PIP_OTHERS_LISTING}`, data);
export const getPIPDetailsForViewAPI = (data) => getRequest(`${PIP_VIEW_PIP_PLAN}/${data}`, data);
export const deletePIPPlanAPI = (data) => deleteRequest(`${PIP_CREATE_PIP_PLAN}?${data}`, data);

export const savePipCommentsAPI = (data) => postRequest(`${PIP_SAVE_COMMENT}`, data);
export const deletePipCommentAPI = (data) => deleteRequest(`${PIP_DELETE_COMMENT}?${data}`, data);
export const getPipCommentsAPI = (data) => getRequest(`${PIP_GET_COMMENT}?${data}`, data);
export const getReviewerDatesAPI = (data) => getRequest(`${PIP_GET_REVIEWER_DATE}?${data}`, data);
export const pipResolveStatusAPI = (data) => putRequest(`${PIP_RESOLVE_STATUS}`, data);
export const getResolveStatusAPI = (data) => getRequest(`${PIP_RESOLVE_PLAN_STATUS}`, data);
export const getPlanReasonAPI = (data) => getRequest(`${PIP_PLAN_REASON}`, data);
export const getFeedbackReasonAPI = (data) => getRequest(`${FEEDBACK_PLAN_REASON}`, data);
export const checkPipNomineeExistAPI = (data) => getRequest(`${PIP_CHECK_NOMINEE_EXISTS}/${data}`, data);
export const getPipAlertApi = (data) => getRequest(`${PIP_ALERT}`, data);

// Feedback Tab API calls
export const getNomineeFeedbackListAPI = (data) => getRequest(`${NOMINEE_FEEDBACK_LIST}/${data}`, data);
export const getFeedbackListing = (data) => postRequest(`${FEEDBACK_MY_LISTING}`, data);
export const getFeedbackDetailsForViewAPI = (data) => getRequest(`${FEEDBACK_VIEW_PIP_PLAN}/${data}`, data);
export const getOtherFeedbackListing = (data) => postRequest(`${FEEDBACK_OTHERS_LISTING}`, data);
export const getFeedbackPermission = (data) => getRequest(`${FEEDBACK_PERMISSION}`, data);
export const getFeedbackCategory = (data) => getRequest(`${FEEDBACK_CATEGORY}?${data}`, data);
export const getFeedbackStatus = (data) => getRequest(`${FEEDBACK_STATUS}?${data}`, data);
export const getFeedbackType = (data) => getRequest(`${FEEDBACK_TYPE}?${data}`, data);
export const postFeedbackAPI = (data) => postRequest(`${POST_FEEDBACK}`, data);
export const putFeedbackAPI = (data) => putRequest(`${POST_FEEDBACK}`, data);
export const getFeedbackStatusDetailsAPI = (data) => getRequest(`${FEEDBACK_STATUS}`, data);
export const feedbackResolveStatusAPI = (data) => putRequest(`${FEEDBACK_RESOLVE_STATUS}`, data);
export const deleteFeedbackPlanAPI = (data) => deleteRequest(`${FEEDBACK_CREATE_FEEDBACK_PLAN}?${data}`, data);
export const getFeedbackAlertApi = (data) => getRequest(`${FEEDBACK_ALERT}`, data);

export const deleteUploadedFilesAPI = (params, data) =>
	deleteRequest(`${DELETE_UPLOADED_CONVERSATION}?${params}`, data);
export const getDownloadPipDetailsAPI = (data) => getRequest(`${PIP_DETAILS_PDF}/${data}`, data);
