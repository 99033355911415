/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Box,
	MobileStepper,
	Typography,
	Drawer,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	List,
	ListItem,
	ListItemText,
	Link,
} from '@material-ui/core';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { DownArrowIcon, LeftArrow, RightArrow, RightArrowIcon } from '../../config/svg/ArrowSvg';
import { HOME, USER_VIEW, ORGANIZATIONS } from '../../config/app-url';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { Fragment } from 'react';
import AlertDialog from '../Common/Dialog';
import { LOGIN_INITIATOR_URL } from '../../config/app-url';
import { OKRButton } from '../Common/OKRButton';
import { CycleIcon, PastCycleIcon } from '../../config/svg/CommonSvg';
import { Enums } from '../../config/enums';
import { showOnCreatePopup } from '../../action/common';

const TenureStepper = (props) => {
	const { stepper, handleTenureList, currentCycleId, setSelectedCycle } = props || [];

	const [activeStep, setActiveStep] = useState(stepper.findIndex((item) => item.active));
	const maxSteps = stepper ? stepper.length : 0;
	const theme = '';
	const dispatch = useDispatch();
	const { okrCreationInProgress } = useSelector((state) => state.commonReducer);
	const { t } = useTranslation();
	const handleNext = (event) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		props.resetOkr();
		// if (props.dashboardCurrentTab === 4 && props.currentRoute === HOME) {
		// 	props.currentDashboardTab(0);
		// }
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		props.updateStepper(stepper[activeStep + 1]?.id, stepper[activeStep + 1], stepper[activeStep + 1]?.year);
	};

	const handleBack = (event) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		props.resetOkr();
		// if (props.dashboardCurrentTab === 4 && props.currentRoute === HOME) {
		// 	props.currentDashboardTab(0);
		// }
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		props.updateStepper(stepper[activeStep - 1]?.id, stepper[activeStep - 1], stepper[activeStep - 1]?.year);
	};

	useEffect(() => {
		setActiveStep(stepper.findIndex((item) => item.active));
	}, [stepper]);

	useEffect(() => {
		if (stepper[activeStep]?.organisationCycleId < currentCycleId) {
			props.setPastCycles(true);
		} else {
			props.setPastCycles(false);
		}
		// props.resetOkr();
		setSelectedCycle(stepper[activeStep]);
		props.getUserSelectedCycle(stepper[activeStep]);
	}, [activeStep]);

	return (
		<Box
			className={`tenure-stepper ${
				stepper[activeStep]?.organisationCycleId !== currentCycleId ? 'not-current-cycle' : ''
			}`}
		>
			<OKRButton
				title={`${
					props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
						? t('futureCycle')
						: props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID
						? t('pastCycle')
						: t('currentCycle')
				}`}
				text={stepper[activeStep] ? stepper[activeStep].label : ''}
				variant='contained'
				className='tenure-stepper-text'
				handleClick={handleTenureList}
				id='tenure-btn'
				icon={<DownArrowIcon />}
			/>

			<MobileStepper
				steps={maxSteps}
				position='static'
				variant='progress'
				activeStep={activeStep}
				nextButton={
					<OKRButton
						title={`${t('nextCycle')}`}
						className='tenure-next'
						id='tenure-btn-next'
						handleClick={(e) => {
							handleNext(e);
						}}
						disabled={activeStep === maxSteps - 1}
						icon={theme.direction === 'rtl' ? <LeftArrow /> : <RightArrow />}
					/>
				}
				backButton={
					<OKRButton
						title={`${t('previousCycle')}`}
						className='tenure-previous'
						id='tenure-btn-previous'
						handleClick={(e) => {
							handleBack(e);
						}}
						disabled={activeStep === 0}
						icon={theme.direction === 'rtl' ? <RightArrow /> : <LeftArrow />}
					/>
				}
			/>
		</Box>
	);
};

export default function TenureNavigation(props) {
	const { getCycleDetails, cycleDetailsAction } = props;
	const { t } = useTranslation();
	const { isCycleChanged, okrCreationInProgress } = useSelector((state) => state.commonReducer);

	const history = useHistory();
	const [organizationCycle, setOrganizationCycle] = useState([]);
	const [openTenureList, setOpenTenureList] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [stepper, setStepper] = useState([]);
	const [currentCycleId, setCurrentCycleId] = useState(0);
	const [selectedCycle, setSelectedCycle] = useState({});
	const [currentCycleObj, setCurrentCycleObj] = useState({});
	const [modalProps, setModalProps] = useState({ open: false, message: '' });
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	if (!userDetail) {
		window.location = LOGIN_INITIATOR_URL;
	}
	const [pastCycles, setPastCycles] = useState(false);
	const dispatch = useDispatch();

	const showErrorPopup = () => {
		if (getLocalStorageItem('LoginAs') === 'user') {
			setModalProps({
				open: true,
				message: t('notStardedCycle'),
			});
		}
	};

	const handleCloseModal = () => {
		setModalProps({
			open: false,
			message: ``,
		});
	};

	useEffect(() => {
		if (openTenureList) {
			getCycleDetails(`organisationId=${userDetail.organisationId}`).then((resp) => {
				if (resp.status === 200) {
					try {
						if (resp.data.entity && resp.data.entity.cycleStart === 'Active') {
							setLocalStorageItem('cycleDetail', JSON.stringify(resp.data.entity));
							setOrganizationCycle(resp.data.entity);
							setStepperDetail(resp.data.entity);
						} else if (resp.data.entity && resp.data.entity.cycleStart === 'InActive') {
							showErrorPopup();
						}
					} catch (err) {}
				}
			});
		}
	}, [openTenureList]);

	useEffect(() => {
		if (userDetail) {
			if (!getLocalStorageItem('cycleDetail')) {
				getCycleDetails(`organisationId=${userDetail.organisationId}`).then((resp) => {
					if (resp.status === 200) {
						try {
							if (resp.data.entity && resp.data.entity.cycleStart === 'Active') {
								setLocalStorageItem('cycleDetail', JSON.stringify(resp.data.entity));
								setOrganizationCycle(resp.data.entity);
								setStepperDetail(resp.data.entity);
								props.teamCycleDetails(cycleDetails.cycleDetails);
							} else if (resp.data.entity && resp.data.entity.cycleStart === 'InActive') {
								showErrorPopup();
							}
						} catch (err) {}
					}
				});
			} else {
				const cycleDetails = JSON.parse(getLocalStorageItem('cycleDetail'));
				setOrganizationCycle(cycleDetails);
				setStepperDetail(cycleDetails);
				props.teamCycleDetails(cycleDetails.cycleDetails);
				if (getLocalStorageItem('redirectUrl')) {
					//redirectToUrl();
				}
			}
		}
	}, [isCycleChanged]);

	const redirectToUrl = async () => {
		let redirectUrl = getLocalStorageItem('redirectUrl');
		if (Boolean(redirectUrl)) {
			redirectUrl = JSON.parse(redirectUrl);
			if (redirectUrl.url && redirectUrl.url.includes('people-view')) {
				setLocalStorageItem('LoginAs', 'user');
				if (redirectUrl.noteId && redirectUrl.noteId > 0) {
					setLocalStorageItem('notesHighlight', JSON.stringify(redirectUrl));
				}
				if (redirectUrl.conversationId && redirectUrl.conversationId > 0) {
					setLocalStorageItem('conversationHighlight', JSON.stringify(redirectUrl));
				}
				const data = 'empId=' + redirectUrl.employeeId;
				const goalId = redirectUrl.objectiveId;
				const resp = await props.getUserById(data);
				if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
					if (resp.data.entityList) {
						setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList));
					}
					setLocalStorageItem('goalType', redirectUrl.goalType);
					setLocalStorageItem('highlightGoal', goalId);
					removeLocalStorageItem('redirectUrl');
					history.push(USER_VIEW);
				}
			} else if (redirectUrl.url && redirectUrl.url.includes('/')) {
				//further redirect will happen in header/Notification.js
				if (redirectUrl.url.includes('Feedback')) {
					setLocalStorageItem('LoginAs', 'user');
					history.push(HOME);
				} else if (redirectUrl.url.includes('KRAcceptDecline')) {
					setLocalStorageItem('LoginAs', 'user');
					const itemArray = redirectUrl.url.split('/');
					const goalId = itemArray[2] ? itemArray[2] : 0;
					setLocalStorageItem('highlightGoal', goalId);
					setLocalStorageItem('reloadComplete', 'user');
					history.push(HOME);
				} else if (redirectUrl.url.includes('Comments')) {
					setLocalStorageItem('LoginAs', 'user');
					history.push(HOME);
					setLocalStorageItem('reloadComplete', 'user');
				} else if (redirectUrl.url.includes('Contributors')) {
					setLocalStorageItem('LoginAs', 'user');
					const itemArray = redirectUrl.url.split('/');
					const goalId = itemArray[1] ? itemArray[1] : 0;
					setLocalStorageItem('highlightGoal', goalId);
					removeLocalStorageItem('redirectUrl');
					history.push(HOME);
					setLocalStorageItem('reloadComplete', 'user');
				} else if (redirectUrl.url.includes('Organization')) {
					setLocalStorageItem('LoginAs', 'admin');
					history.push(ORGANIZATIONS);
				} else if (redirectUrl.url.includes('RequestOneOnOne')) {
					setLocalStorageItem('LoginAs', 'user');
					const itemArray = redirectUrl.url.split('/');
					const goalId = itemArray[1] ? itemArray[1] : 0;
					setLocalStorageItem('highlightGoal', goalId);
					removeLocalStorageItem('redirectUrl');
					setLocalStorageItem('reloadComplete', 'user');
					history.push(HOME);
				} else if (redirectUrl.url.includes('krAssignmentOpenDrawer')) {
					setLocalStorageItem('LoginAs', 'user');
					removeLocalStorageItem('redirectUrl');
					setLocalStorageItem('reloadComplete', 'user');
					history.push(HOME);
				} else if (redirectUrl.url.includes('userdeleted')) {
					setLocalStorageItem('LoginAs', 'user');
					removeLocalStorageItem('redirectUrl');
					setLocalStorageItem('reloadComplete', 'user');
					history.push(HOME);
				}else if (redirectUrl.url.includes('onetoone')) {
					setLocalStorageItem('LoginAs', 'user');
					removeLocalStorageItem('redirectUrl');
					setLocalStorageItem('reloadComplete', 'user');
					history.push(HOME);
				} else if (redirectUrl.url.includes('unlockaccount')) {
					// handles redirection click from email
					setLocalStorageItem('unlockUser', true);
					removeLocalStorageItem('redirectUrl');
					// history.push(HOME);

					window.history.replaceState(null, '', 'unlock-me'); //added this because page is getting refreshed twice
				}
			} else if (redirectUrl.url && redirectUrl.url.includes('unlock-me')) {
				setLocalStorageItem('LoginAs', 'user');
				removeLocalStorageItem('redirectUrl');
				history.push(HOME);
			} else {
				removeLocalStorageItem('redirectUrl');
				history.push(redirectUrl.url);
			}
		} else {
			history.push(HOME);
		}
	};

	const setYearAndQuarter = (data) => {
		const cycleDetails = data.cycleDetails.find((item) => item.isCurrentYear);
		if (cycleDetails) {
			const { year } = cycleDetails;
			const currentDetail = cycleDetails.quarterDetails.find((item) => item.isCurrentQuarter);
			if (currentDetail) {
				const { organisationCycleId, endDate, startDate } = currentDetail;
				setCurrentCycleObj(currentDetail);
				props.currentCycleIdObject({
					...currentDetail,
					year: year,
				});
				setCurrentCycleId(organisationCycleId);

				if (!getLocalStorageItem('year')) {
					setLocalStorageItem('currentYear', year);
					setLocalStorageItem('year', year);
				}
				if (!getLocalStorageItem('cycleId')) {
					setLocalStorageItem('cycleId', organisationCycleId);
				}
				if (!getLocalStorageItem('currentCycleId')) {
					setLocalStorageItem('currentCycleId', organisationCycleId);
				}
				if (!getLocalStorageItem('cycleEndDate')) {
					setLocalStorageItem('currentCycleEndDate', endDate);
					setLocalStorageItem('cycleEndDate', endDate);
				}
				if (!getLocalStorageItem('cycleStartDate')) {
					setLocalStorageItem('cycleStartDate', startDate);
				}
				setLocalStorageItem('cycleDuration', data.cycleDuration);
			}
		}
	};

	useEffect(() => {
		let saveCycleObject = { index: 0, quarter: {} };
		let cycleId = getLocalStorageItem('cycleId');
		if (organizationCycle.cycleDetails && organizationCycle.cycleDetails.length) {
			let currentCycleObj = organizationCycle.cycleDetails.filter((item) =>
				item.quarterDetails.some((quarter, index) => {
					if (quarter.organisationCycleId === parseInt(currentCycleId || cycleId)) {
						saveCycleObject = {
							cycleYear: item.year,
							quarter: quarter,
							index: `${index + 1}${item.year}`,
						};
					}
				})
			);
			redirectTo(saveCycleObject.index, saveCycleObject.quarter, saveCycleObject.cycleYear);
			setCurrentCycle(currentCycleObj);
		}
	}, [props.currentCycleId]);

	const setStepperDetail = (data) => {
		const cycleDetails = data.cycleDetails.find((item) => item.isCurrentYear);
		if (cycleDetails) {
			const { organisationCycleId } = cycleDetails.quarterDetails.find((item) => item.isCurrentQuarter);
			let updatedDetail = [];
			setYearAndQuarter(data);
			data.cycleDetails.forEach((item) => {
				if (item.quarterDetails && item.quarterDetails.length > 0) {
					item.quarterDetails.reverse().forEach((q, idx) => {
						// if (q.organisationCycleId <= organisationCycleId) {
						const json = { active: false, label: '', year: item.year, id: '', quarter: 0 };
						const navId = getLocalStorageItem('navigationId');
						const cycleId = getLocalStorageItem('cycleId');
						// json.active =  navId && navId === `${idx + 1}${item.year}` ? true : q.isCurrentQuarter;
						json.active =
							navId && navId === `${idx + 1}${item.year}`
								? true
								: cycleId && q.organisationCycleId === parseInt(cycleId)
								? q.isCurrentQuarter
								: false;
						json.label = `${q.symbol}, ${item.year}`;
						json.id = `${idx + 1}${item.year}`;
						json.organisationCycleId = q.organisationCycleId;
						json.endDate = q.endDate;
						json.startDate = q.startDate;
						json.cycleStatus = q.cycleStatus;
						json.symbol = q.symbol;
						//json.hide = q.organisationCycleId > currentCycleId ? true : false;
						updatedDetail = [...updatedDetail, json];
						// }
					});
				}
			});
			setStepper(updatedDetail);
		}
	};

	const [cycleChanged, setCycleChanged] = useState(false);
	const [currentCycle, setCurrentCycle] = useState(false);

	let cycleDetails = JSON.parse(getLocalStorageItem('otherCycleObjective')) || {};
	let cycleId = cycleDetails.cycleId;
	let cycleYear = cycleDetails.year;
	let cycleList = JSON.parse(getLocalStorageItem('cycleDetail')) || {};

	useEffect(() => {
		if (
			cycleId &&
			cycleYear &&
			stepper.length &&
			!cycleChanged &&
			cycleList &&
			cycleList.cycleDetails &&
			cycleList.cycleDetails.length
		) {
			let cycleDetail = JSON.parse(getLocalStorageItem('cycleDetail'));
			let saveCycleObject = { index: 0, quarter: {} };
			let currentCycleObj = cycleDetail.cycleDetails.filter((item) =>
				item.quarterDetails.reverse().some((quarter, index) => {
					if (quarter.organisationCycleId === parseInt(cycleId)) {
						saveCycleObject = {
							quarter: quarter,
							index: `${index + 1}${cycleYear}`,
						};
					}
				})
			);
			redirectTo(saveCycleObject.index, saveCycleObject.quarter, cycleYear);
			setCycleChanged(true);
			setCurrentCycle(currentCycleObj);
			removeLocalStorageItem('otherCycleObjective');
		}
	}, [cycleDetails, stepper]);

	useEffect(() => {
		if (cycleDetailsAction.id && stepper.length && !cycleChanged) {
			setCycleChanged(true);
			redirectTo(cycleDetailsAction.id, cycleDetailsAction.quarterData, cycleDetailsAction.year);
			let cycleObj = {
				cycleChangedStatus: true,
			};
			props.changeCycleId(cycleObj);
		}
	}, [cycleDetailsAction, stepper]);

	const handleTenureList = (event) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		setOpenTenureList(true);
	};
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handleDrawerClose = () => {
		setOpenTenureList(false);
	};

	const redirectTo = (id, quarterData, year) => {
		props.resetOkr();
		handleDrawerClose();
		setExpanded(false);
		// props.currentDashboardTab(0);
		updateStepper(id, quarterData, year);
	};

	const updateStepper = (id, quarterData, year) => {
		updateCurrentSelected(id);
		setLocalStorageItem('navigationId', id);
		setLocalStorageItem('year', year);
		setLocalStorageItem('cycleId', quarterData.organisationCycleId);
		// setLocalStorageItem('currentCycleId', quarterData.organisationCycleId);
		setLocalStorageItem('cycleEndDate', quarterData.endDate);
		setLocalStorageItem('cycleStartDate', quarterData.startDate);

		history.push(props.location.pathname);
		props.updateRoute(props.location.pathname);
		props.handleToggle(2);
	};

	const updateCurrentSelected = (id) => {
		const updatedStepper = stepper.map((data) => {
			if (parseInt(data.id) === parseInt(id)) {
				data.active = true;
			} else {
				data.active = false;
			}
			return data;
		});
		setStepper(updatedStepper);
	};

	const handlePastCycles = () => {
		setPastCycles(!pastCycles);
	};

	return (
		<Fragment>
			{getLocalStorageItem('LoginAs') === 'user' && (
				<Box className='tenure-navigation'>
					{stepper.length > 0 && (
						<TenureStepper
							currentCycleId={currentCycleId}
							handleTenureList={handleTenureList}
							stepper={stepper}
							updateStepper={updateStepper}
							setPastCycles={setPastCycles}
							setSelectedCycle={setSelectedCycle}
							getUserSelectedCycle={props.getUserSelectedCycle}
							userSelectedCycle={props.userSelectedCycle}
							resetOkr={props.resetOkr}
							currentDashboardTab={props.currentDashboardTab}
							currentRoute={props.currentRoute}
						/>
					)}
					<Drawer
						className='tenure-drawer'
						anchor='right'
						open={openTenureList}
						// onEscapeKeyDown={handleDrawerClose}
						onClose={handleDrawerClose}
						id='tenure-drawer'
					>
						{/* {stepper.length > 0 && (
							<TenureStepper handleTenureList={handleTenureList} stepper={stepper} updateStepper={updateStepper} />
						)} */}
						<Box className='tenure-list' id='tenure-list'>
							{pastCycles && (
								<>
									<Box className='back-to-main'>
										<Typography variant='body2'>
											<Link id='back-btn' onClick={handlePastCycles}>
												<BackArrow /> {t('back')}
											</Link>
											{t('yourPastOKRsCycles')}
										</Typography>
									</Box>
									{organizationCycle &&
										organizationCycle.cycleDetails &&
										organizationCycle.cycleDetails
											.map((keys, idx) => {
												return (
													(keys.cycleStatus === Enums.PAST_CYCLE_ID || keys.cycleStatus === Enums.CURRENT_CYCLE_ID) && (
														<ExpansionPanel
															key={`navigation-${idx}`}
															expanded={
																keys.quarterDetails.find(
																	(item) =>
																		item.organisationCycleId === selectedCycle.organisationCycleId &&
																		item.cycleStatus === Enums.PAST_CYCLE_ID
																)
																	? true
																	: expanded === keys
															}
															onChange={handleChange(keys)}
															id={`navigation-${idx}`}
														>
															<ExpansionPanelSummary
																expandIcon={<RightArrowIcon />}
																aria-controls='panel1a-content'
																id='panel1a-header'
															>
																<Typography>
																	Year {keys.year}
																	{keys.isOkrAvailable && <CycleIcon />}
																</Typography>
															</ExpansionPanelSummary>
															<ExpansionPanelDetails>
																<List component='nav' aria-label={t('HeaderTenureNavigation')}>
																	{
																		keys.quarterDetails &&
																			keys.quarterDetails.map((quarter, qid) => {
																				return (
																					quarter.cycleStatus === Enums.PAST_CYCLE_ID && (
																						<ListItem
																							className={
																								selectedCycle &&
																								selectedCycle.organisationCycleId &&
																								selectedCycle.organisationCycleId === quarter.organisationCycleId
																									? 'active'
																									: ''
																							}
																							button
																							key={`tenure${quarter.symbol}${keys.year}${qid}`}
																							id={`tenure-${quarter.symbol}${keys.year}${qid}`}
																						>
																							<ListItemText
																								primary={`${quarter.symbol}, ${keys.year}`}
																								secondary={quarter.isOkrAvailable ? <CycleIcon /> : <></>}
																								onClick={(e) => {
																									e.preventDefault();
																									redirectTo(`${qid + 1}${keys.year}`, quarter, keys.year);
																								}}
																							/>
																							{quarter.isCurrentQuarter && (
																								<Typography variant='subtitle2' className='current-cycle-tag'>
																									{t('currentCycle')}
																								</Typography>
																							)}
																						</ListItem>
																					)
																				);
																			})
																		// .reverse()
																	}
																</List>
															</ExpansionPanelDetails>
														</ExpansionPanel>
													)
												);
											})
											.reverse()}
								</>
							)}
							{!pastCycles && (
								<>
									{
										organizationCycle &&
											organizationCycle.cycleDetails &&
											organizationCycle.cycleDetails.map((keys, idx) => {
												return (
													(keys.cycleStatus === Enums.CURRENT_CYCLE_ID ||
														keys.cycleStatus === Enums.FUTURE_CYCLE_ID) && (
														<ExpansionPanel
															key={`navigation-${idx}`}
															expanded={
																selectedCycle &&
																selectedCycle.organisationCycleId &&
																keys.quarterDetails.find(
																	(item) => item.organisationCycleId === selectedCycle.organisationCycleId
																)
																	? true
																	: expanded === keys
															}
															onChange={handleChange(keys)}
															id={`navigation-${idx}`}
														>
															<ExpansionPanelSummary
																expandIcon={<RightArrowIcon />}
																aria-controls='panel1a-content'
																id='panel1a-header'
															>
																<Typography>
																	Year {keys.year}
																	{keys.isOkrAvailable && <CycleIcon />}
																</Typography>
															</ExpansionPanelSummary>
															<ExpansionPanelDetails>
																<List component='nav' aria-label={t('HeaderTenureNavigation')}>
																	{
																		keys.quarterDetails &&
																			keys.quarterDetails.map((quarter, qid) => {
																				return (
																					(quarter.cycleStatus === 2 || quarter.cycleStatus === 3) && (
																						<ListItem
																							className={
																								selectedCycle &&
																								selectedCycle.organisationCycleId &&
																								selectedCycle.organisationCycleId === quarter.organisationCycleId
																									? 'active'
																									: ''
																							}
																							button
																							key={`tenure${quarter.symbol}${keys.year}${qid}`}
																							id={`tenure-${quarter.symbol}${keys.year}${qid}`}
																						>
																							<ListItemText
																								// className={`${quarter.isCurrentQuarter && 'active'}`}
																								primary={`${quarter.symbol}, ${keys.year}`}
																								secondary={quarter.isOkrAvailable ? <CycleIcon /> : <></>}
																								onClick={() => redirectTo(`${qid + 1}${keys.year}`, quarter, keys.year)}
																							/>
																							{quarter.isCurrentQuarter && (
																								<Typography variant='subtitle2' className='current-cycle-tag'>
																									{t('currentCycle')}
																								</Typography>
																							)}
																						</ListItem>
																					)
																					// )
																				);
																			})
																		// .reverse()
																	}
																</List>
															</ExpansionPanelDetails>
														</ExpansionPanel>
													)
												);
											})
										// .reverse()
									}
									<Box className='past-cycle-link' onClick={handlePastCycles}>
										<PastCycleIcon />
										{t('pastCycles')}
									</Box>
								</>
							)}
						</Box>
					</Drawer>
				</Box>
			)}
			{modalProps.open && (
				<AlertDialog
					module='header'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
					isCancel={true}
				/>
			)}
		</Fragment>
	);
}
