import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { EmailRecipientSetting } from '../Common/EmailRecipientSetting';
import { EnumEmailModule, EnumEmailRecipients, Enums } from '../../../../../config/enums';
import {
	getEmailRecipient,
	updateEmailRecipient,
} from '../../../../../action/adminPerformanceDevelopment';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';
import AlertDialog from '../../../../Common/Dialog';
import { getOkrMasterData } from '../../../../../action/myGoal';

export const AdminThreeSixtyExperience: React.FC<any> = (props: any) => {
	const { loader, setLoader } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const [twoFactorModalProps, setTwoFactorModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [modalFeedbackProps, setModalFeedbackProps] = useState<any>({
		open: false,
		details: null,
		type: '',
		message: '',
		isCancel: false,
	});

	useEffect(() => {
		dispatch(getOkrMasterData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//HERE
	return (
		<>
			<Box className='performance-development-settings'>
				<EmailRecipientSetting
					t={t}
					loader={loader}
					messageNote={t('sendUpdateEmailsToNote360Experience')}
					emailModuleId={EnumEmailModule.ThreeSixtyExperience}
					setLoader={setLoader}
				/>
			</Box>
		</>
	);
};
