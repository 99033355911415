import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { Conversations } from '../../Goals/Cfr/Conversations';
import { ConversationsGoal } from '../Conversations';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';

export const CategoryConversations: React.FC<any> = (props) => {
	const { t, setLoader, tabSelected } = props;
	const [conversationCount, setConversationCount] = React.useState<Number>(0);
	const [isTaskEdited, setIsTaskEdited] = React.useState<boolean>(false);
	const [hideBox, setHideBox] = React.useState<boolean>(false);

	return (
		<Box className=''>
			{/* <InformationMessage messageText={t('goalSettingConversationInfo')} icon={true} /> */}
			<ConversationsGoal
				{...props}
				setLoader={setLoader}
				tabSelected={tabSelected}
				conversationCount={conversationCount}
				setConversationCount={setConversationCount}
				isTaskEdited={isTaskEdited}
				setIsTaskEdited={setIsTaskEdited}
				//setUnsavedCfrProps={setUnsavedCfrProps}
				setHideBox={setHideBox}
				hideBox={hideBox}
				userTagged={[]}
				type={3}
				kr={{ ...props.kr, goalKeyId: props?.kr?.performanceGoalLinkedFormCategoryId }}
				setUserTaggged={() => {}}
			/>
		</Box>
	);
};
