import { Font, StyleSheet } from '@react-pdf/renderer';
import RobotoRegular from '../../../fonts/Roboto-Regular.ttf';
import RobotoMedium from '../../../fonts/Roboto-Medium.ttf';
import RobotoLight from '../../../fonts/Roboto-Light.ttf';
import RobotoBold from '../../../fonts/Roboto-Bold.ttf';

// Register Roboto font variants
Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: RobotoRegular,
			fontWeight: 'normal',
		},
		{
			src: RobotoMedium,
			fontWeight: 'medium',
		},
		{
			src: RobotoLight,
			fontStyle: 'italic',
		},
		{
			src: RobotoBold,
			fontWeight: 'bold',
		},
	],
});
export const styles = StyleSheet.create({
	pageFirst: {
		padding: 15,
		backgroundColor: '#ffffff',
		fontFamily: 'Roboto',
		fontWeight: 'normal',
		fontSize: 12,
	},
	pageFirst_wrap: {
		padding: 30,
		borderRadius: 6,
		backgroundColor: '#f4f8ff',
	},
	pageHeaderSection: {
		marginBottom: 20,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
	},
	unlockULogo: {
		width: 25,
		height: 29,
	},
	pipMainHeader: {
		paddingBottom: 20,
	},
	pipMainHeaderText: {
		paddingBottom: 5,
		fontSize: 12,
		color: '#416390',
	},
	pipDuration: {
		display: 'flex',
		flexDirection: 'row',
	},
	pipDurationHead: {
		fontSize: 9,
		color: '#416390',
	},
	pipDurationDate: {
		fontSize: 9,
	},
	fontRegular: {
		fontFamily: 'Roboto',
		fontWeight: 'normal',
	},
	fontMedium: {
		fontFamily: 'Roboto',
		fontWeight: 'medium',
	},
	fontBold: {
		fontFamily: 'Roboto',
		fontWeight: 'bold',
	},
	employeeRow: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 20,
	},
	employeeCol: {
		width: '50%',
		paddingRight: 10,
	},
	employeeColThird: {
		width: '33.333%',
		paddingRight: 10,
	},
	employeeColFull: {
		width: '100%',
	},
	employeeColTitle: {
		paddingBottom: 3,
		fontSize: 9,
		color: '#416390',
	},
	employeeColText: {
		fontSize: 10,
	},
	timeframeAlertText: {
		color: '#ff0000',
	},
	page: {
		width: '100%',
		backgroundColor: '#ffffff',
		paddingTop: 35,
		paddingBottom: 35,
		fontFamily: 'Roboto',
		fontWeight: 'normal',
		fontSize: 12,
	},
	page_wrap: {
		paddingTop: 0,
		paddingHorizontal: 0,
		paddingLeft: 30,
		paddingRight: 30,
	},
	header: {
		position: 'absolute',
		top: 15,
		left: 30,
		right: 30,
		height: 45,
		paddingBottom: 5,
	},
	headerText: {
		fontSize: 12,
		color: '#d5dae1',
	},
	customerLogo: {
		width: 65,
		height: 41,
	},
	footer: {
		position: 'absolute',
		bottom: 15,
		left: 30,
		right: 30,
		height: 35,
		borderTop: '1 solid #dcdbdc',
		paddingTop: 5,
	},
	footerInner: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
	},
	unlockUfooterLogo: {
		width: 70,
		height: 18,
	},
	infoProLearn_text: {
		paddingTop: 10,
		paddingBottom: 10,
		color: '#000000',
		fontSize: 8,
	},
	pipSectionTitle: {
		paddingBottom: 5,
		marginBottom: 15,
		borderBottom: '1 solid #dcdbdc',
	},
	pipSectionTitleText: {
		fontSize: 12,
		paddingBottom: 3,
	},
	pipBlockHead: {
		backgroundColor: '#e5f0ff',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 5,
	},
	pipBlockHeadTitle: {
		fontSize: 11,
		paddingBottom: 2,
	},
	pipBlockHeadDesc: {
		fontSize: 9,
		color: '#416390',
		lineHeight: 1.5,
	},
	editorBox: {
		paddingTop: 10,
		paddingBottom: 10,
		width: '100%',
	},
	taskPanel: {
		marginBottom: 10,
		backgroundColor: '#f4f8ff',
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 6,
		border: '1 solid #cee3ff',
	},
	taskTitle: {
		marginBottom: 3,
		fontSize: 10,
	},
	taskDetails: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	taskDetail: {
		marginRight: 5,
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	reviewContentBlock: {
		marginBottom: 10,
	},
	reviewContentHead: {
		backgroundColor: '#e5f0ff',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 10,
		paddingRight: 10,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	reviewContentDetails: {
		backgroundColor: '#f4f8ff',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 10,
		paddingRight: 10,
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},
	reviewInitiator: {
		fontSize: 10,
		paddingBottom: 2,
	},
	reviewInitiatedDate: {
		fontSize: 9,
		color: '#416390',
		lineHeight: 1.5,
	},
	reviewerTitle: {
		paddingBottom: 5,
		color: '#416390',
		fontSize: 9,
	},
	reviewersDetailsSetion: {
		paddingRight: 10,
	},
	reviewerName: {
		fontSize: 9,
		lineHeight: 1.5,
		color: '#292929',
	},
	reviewerTextSeparator: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: 9,
		width: 3,
		height: 3,
		borderRadius: 3,
		backgroundColor: '#e5f0ff',
	},
	commentsList: {
		paddingTop: 10,
		paddingBottom: 10,
	},
	commentTitle: {
		paddingBottom: 5,
		color: '#416390',
		fontSize: 9,
	},
	commentsUserInfo: {
		marginBottom: 10,
	},
	user_image: {
		width: 20,
		height: 20,
		borderRadius: 20,
		marginRight: 5,
		marginTop: 5,
	},
	user_initial: {
		marginTop: 5,
		marginRight: 5,
		width: 20,
		height: 20,
		borderRadius: 20,
		backgroundColor: '#ffdbdb',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	user_image_initiator: {
		marginTop: 0,
	},
	user_initial_name: {
		fontSize: 9,
		color: '#b24002',
		textTransform: 'uppercase',
	},
	commentsUserInfoInner: {
		backgroundColor: '#ffffff',
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 6,
	},
	greyTitle: {
		marginRight: 2,
		fontSize: 9,
		lineHeight: 1.5,
		color: '#909090',
	},
	blackSmallText: {
		fontSize: 9,
		lineHeight: 1.5,
		color: '#292929',
	},
	resolvedBlock: {
		paddingBottom: 10,
	},
	resolvedPanel: {
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10,
		backgroundColor: '#f4f8ff',
		borderRadius: 6,
		border: '1 solid #cee3ff',
	},
	resolveTitle: {
		paddingBottom: 5,
		color: '#416390',
		fontSize: 9,
	},
	// reviewerList: {
	// 	display: 'flex',
	// 	justifyContent: 'flex-start',
	// 	flexDirection: 'row',
	// 	alignItems: 'center',
	// 	flexWrap: 'wrap',
	// 	paddingTop: 5,
	// 	paddingBottom: 5,
	// 	paddingLeft: 5,
	// 	paddingRight: 5,
	// 	backgroundColor: '#f4f8ff',
	// },
	// reviewerTitle: {
	// 	fontSize: 11,
	// 	marginRight: 5,
	// },
	// question: {
	// 	fontSize: 11,
	// },
	// assessmentAns: {
	// 	paddingLeft: 10,
	// 	paddingRight: 10,
	// 	paddingTop: 10,
	// 	paddingBottom: 10,
	// },
	// assessmentAnsTitle: {
	// 	paddingBottom: 5,
	// 	color: '#416390',
	// 	fontSize: 9,
	// },
	// assessmentScaleSection: {
	// 	paddingRight: 10,
	// 	display: 'flex',
	// 	justifyContent: 'flex-start',
	// 	flexDirection: 'row',
	// 	alignItems: 'center',
	// 	flexWrap: 'wrap',
	// },
	// shortDescAnswer: {
	// 	paddingRight: 10,
	// },
	scaleDetails: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
	},
	scaleText: {
		fontSize: 9,
		lineHeight: 1.5,
		color: '#949494',
	},
	scaleTextSelected: {
		fontSize: 9,
		lineHeight: 1.5,
		color: '#292929',
	},
	scaleTextSeparator: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: 9,
		width: 3,
		height: 3,
		borderRadius: 3,
		backgroundColor: '#e5f0ff',
	},
	assessmentCommentSection: {
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10,
	},
	comments_title: {
		paddingBottom: 5,
		color: '#416390',
		fontSize: 9,
	},
	//Editor CSS Start
	h1: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	h2: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	h3: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	h4: {
		fontSize: 9,
		margin: 0,
		color: '#292929',
	},
	h5: {
		fontSize: 9,
		margin: 0,
		color: '#292929',
	},
	h6: {
		fontSize: 9,
		margin: 0,
		color: '#292929',
	},
	p: {
		margin: '0 0 10px 0px',
		padding: 0,
		fontSize: 9,
		fontWeight: 400,
		color: '#292929',
		lineHeight: 1.5,
	},
	a: {
		color: '#0664B1',
		cursor: 'pointer',
	},
	b: {
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	strong: {
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	i: {
		fontStyle: 'italic',
		fontFamily: 'Helvetica-Oblique',
		color: '#292929',
	},
	ul: {
		margin: '5px 0 10px 2px',
		padding: 0,
		fontSize: 9,
		fontWeight: 400,
		lineHeight: 1.5,
		color: '#292929',
		//width: '100px',
		maxWidth: '85%',
	},
	li: {
		fontSize: 9,
		fontWeight: 400,
		lineHeight: 1.5,
		color: '#292929',
	},
	ol: {
		margin: '5px 0 10px 2px',
		padding: 0,
		fontSize: 9,
		lineHeight: 1.5,
		fontWeight: 400,
		color: '#292929',
		//width: '100px',
	},
	table: {
		borderCollapse: 'collapse',
		//width: 'auto',
		marginBottom: 15,
		display: 'table',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: '#cee3ff',
		backgroundColor: '#ffffff',
	},
	tr: {
		flexDirection: 'row',
	},
	td: {
		padding: '8px',
		fontSize: 9,
		fontFamily: 'Roboto',
		fontWeight: 400,
		color: '#292929',
		wordBreak: 'break-word',
	},
	th: {
		padding: '8px',
		fontSize: 9,
		fontFamily: 'Roboto',
		fontWeight: 'bold',
		color: '#292929',
		wordBreak: 'break-word',
	},

	tableRow: {
		margin: 'auto',
		flexDirection: 'row',
	},
	tableCell: {
		marginTop: 5,
		fontSize: 9,
		paddingLeft: 5,
		paddingRight: 5,
		textAlign: 'left',
	},
	tableHeader: {
		fontSize: 9,
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		textAlign: 'left',
		color: '#416390',
	},
	//Editor css end
	quarterTitle: {
		fontSize: 9,
		color: '#416390',
	},
	quarterDetails: {
		backgroundColor: '#e5f0ff',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 5,
	},
	okrPanel: {
		paddingBottom: 15,
	},
	ObjectiveDetails: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 10,
		paddingRight: 10,
		backgroundColor: '#f4f8ff',
	},
	ObjectiveHead: {
		paddingTop: 5,
		paddingBottom: 5,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	ObjectiveTitle: {
		width: '70%',
		fontSize: 10,
	},
	ObjectiveProgress: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
	},
	ObjectivePercentage: {
		marginRight: 10,
		color: '#416390',
		fontSize: 10,
	},
	noContentBlock: {
		paddingBottom: 10,
	},
	noContentText: {
		fontSize: 9,
		color: '#949494',
	},
});
