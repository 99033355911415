import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DownloadIcon } from '../../../config/svg/CommonSvg';
import { getMeetingNotes } from '../../../action/oneOnOneRequest';
import { getUserDetails } from '../../../config/utils';
import { MeetingDetailPdf } from './MeetingDetailPdf';
import { getMonthDateYearTime, convertDateTimeToUserTimezone, generateDefaultTimeOptions } from '../../../config/utils';

export default function MeetingNotesDownload(props) {
	const { itemDetail, setLoader } = props;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const textInput = useRef();

	const staticTitleText = '1-on-1 Meeting';
	const defaultTimeOptions = generateDefaultTimeOptions();
	const staticData = {
		requestId: 0,
		requestedTo: 0,
		onetoOneRemark: '',
		oneToOneTitle: staticTitleText,
		isQuickLaunch: false,
		selectedUser: [],
		meetingLink: '',
		meetingStartDate: new Date(),
		meetingEndDate: new Date(),
		meetingStartTime: defaultTimeOptions[0],
		meetingEndTime: defaultTimeOptions[1],
	};

	const [meetingDetail, setMeetingDetail] = useState(null);
	const [show, setShow] = useState(false);
	// const [loader, setLoader] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			clearTimeout();
		};
	}, []);
	useEffect(() => {
		if (meetingDetail) {
			setShow(true);
			setTimeout(() => {
				textInput.current.click();
				setLoader(false);
			}, 1000);
		}
	}, [meetingDetail]);

	const fetchOkrDetail = async (e, item) => {
		try {
			const requestParams = `OneToOneDetailId=${item?.oneToOneDetailId}`;
			const res = await dispatch(getMeetingNotes(requestParams));
			if (res) {
				if (res && res.data && res.data.status === 200) {
					//setShow(true);
					let data = res.data.entity;
					let localStartDateTime = convertDateTimeToUserTimezone(
						data.meetingStartDate || '',
						data.meetingStartTime || ''
					);
					let localEndDateTime = convertDateTimeToUserTimezone(data.meetingEndDate || '', data.meetingEndTime || '');
					data.meetingStartDate = localStartDateTime[0] || staticData.meetingStartDate;
					data.meetingStartTime = localStartDateTime[1] || staticData.meetingStartTime;
					data.meetingEndDate = localEndDateTime[0] || staticData.meetingEndDate;
					data.meetingEndTime = localEndDateTime[1] || staticData.meetingEndTime;
					setMeetingDetail(data);
				} else {
					setLoader(false);
					setShow(false);
					setMeetingDetail('');
					enqueueSnackbar('Error occurred while downloading Meeting Notes', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			}
		} catch (error) {
			setLoader(false);
			setShow(false);
			setMeetingDetail('');
			enqueueSnackbar('Error occurred while downloading Meeting Notes', {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};

	const downloadPdf = (e, item) => {
		setLoader(true);
		fetchOkrDetail(e, item);
	};
	return (
		<Fragment>
			{/* {loader && <LinearLoader />} */}
			<Fragment>
				<Tooltip title={t('downloadMOM')} arrow>
					<Button className='download-btn btn-link' onClick={(e) => downloadPdf(e, itemDetail)}>
						<DownloadIcon />
					</Button>
				</Tooltip>

				{show && meetingDetail && (
					<PDFDownloadLink
						document={meetingDetail && <MeetingDetailPdf data={meetingDetail} {...props} />}
						fileName={
							meetingDetail &&
							`${'MoM_'}${meetingDetail.meetingStartDate ? meetingDetail.meetingStartDate : ''}${
								meetingDetail.meetingStartDate ? '_' : ''
							}${meetingDetail.oneToOneTitle}.pdf`
						}
						style={{ display: 'none' }}
					>
						<Tooltip title={t('downloadMOM')} arrow>
							<Button className='download-btn' ref={textInput}>
								<DownloadIcon />
							</Button>
						</Tooltip>
					</PDFDownloadLink>
				)}
			</Fragment>
		</Fragment>
	);
}
